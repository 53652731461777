import React from "react";
import styled from "styled-components";
import Select from "Components/Input/Select";

const SelectArea = styled.div`
  display: inline-block;
  width: 350px;
`;

function Sort({ setSort }) {
  const categoryInit = (e) => {
    setSort(e.target.value);
  };

  return (
    <SelectArea>
      <Select onChange={categoryInit} defaultValue={"all"}>
        <option value={"-regDate"} onClick={categoryInit}>
          작성일자△
        </option>
        <option value={"regDate"} onClick={categoryInit}>
          작성일자▽
        </option>
        <option value={"category"} onClick={categoryInit}>
          카테고리(가나다)△
        </option>
        <option value={"-category"} onClick={categoryInit}>
          카테고리(가나다)▽
        </option>
        <option value={"price"} onClick={categoryInit}>
          가격△
        </option>
        <option value={"-price"} onClick={categoryInit}>
          가격▽
        </option>
        <option value={"title"} onClick={categoryInit}>
          이름△
        </option>
        <option value={"-title"} onClick={categoryInit}>
          이름▽
        </option>
      </Select>
    </SelectArea>
  );
}

export default Sort;
