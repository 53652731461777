import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getBannerdata } from "util/banner/banner";
import { getBannerName } from "util/category/category";

const BannerHoverArea = styled.div`
  width: 100%;
  height: 65rem;
  overflow: hidden;

  &:hover {
    & > * {
      transform: scale(1.008);
    }
  }
`;

const Bannerarea = styled.div`
  width: 100%;
  height: 65rem;
  background-image: url(${(props) => props.mainImgUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  transition: all 120ms;
`;

const BannerTextArea = styled.div`
  margin-left: 12rem;
  & * {
    margin: 0;
  }
`;

const BannerTextTitle = styled.h2`
  font-size: 6.4rem;
`;

const BannerTextSub = styled.h3`
  font-size: 3.2rem;
`;

const custom = {
  // main: {
  //   img: "event.gif",
  //   to: "/cs?64914db90f0735a684c09dca",
  //   state: "notice",
  //   title: "",
  //   sub: "",
  // },
};

function Banner() {
  const location = useLocation();
  const state = location.state ? location.state : "main";
  const setBannerData = () => {
    return getBannerdata(state, custom[state]);
  };

  const [banner, setBanner] = useState(setBannerData());

  const mainImgUrl =
    process.env.PUBLIC_URL +
    "/images/" +
    getBannerName(location.pathname, custom[state]);

  useEffect(() => {
    setBanner(setBannerData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const BannerLink = ({ children }) => {
    if (banner.to !== undefined) {
      return (
        <Link to={banner.to} state={banner.state}>
          <BannerHoverArea>{children}</BannerHoverArea>
        </Link>
      );
    }
    return <>{children}</>;
  };

  return (
    <>
      {banner && (
        <BannerLink>
          <Bannerarea mainImgUrl={mainImgUrl}>
            <BannerTextArea>
              <BannerTextTitle>{banner.title}</BannerTextTitle>
              <BannerTextSub>{banner.sub}</BannerTextSub>
            </BannerTextArea>
          </Bannerarea>
        </BannerLink>
      )}
    </>
  );
}

export default Banner;
