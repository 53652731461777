import { Link } from "react-router-dom";
import { media } from "style/media";
import styled from "styled-components";

const LoginBtn = styled.div`
  cursor: pointer;
  color: #999;
  margin: 0 30px 0 80px;
  width: 150px;
  text-align: center;
  font-size: 14px;

  ${media.middle`
		display:none;
  `}
  ${media.small`
		display:none;
  `}

	&::after {
    content: "관리";
  }
`;

const Login = () => {
  return (
    <Link to={"/private"} state={"admin"}>
      <LoginBtn />
    </Link>
  );
};

export default Login;
