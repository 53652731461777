import { ProductContext } from "contexts/ProductContext";
import { useContext } from "react";
import { useState } from "react";
import { media } from "style/media";
import styled from "styled-components";

const DetailMenuArea = styled.div`
  width: 100%;
  margin: 50px 0;
  border-bottom: 1px solid #333;
  display: flex;
  justify-content: center;
  border-collapse: collapse;
  box-sizing: border-box;
  align-items: flex-end;
`;

const DetailMenu = styled.div`
  width: 150px;
  height: ${(props) => (props.clicked === true ? "50px" : "50px")};
  border: ${(props) =>
    props.clicked === true ? "1px solid #333" : "1px solid #999"};
  z-index: ${(props) => (props.clicked === true ? "2" : "1")};
  background-color: #fff;
  border-bottom: 1px solid #fff;
  position: relative;
  top: 1px;
  left: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: ${(props) => (props.clicked === true ? "bold" : "400")};
  cursor: pointer;
  &:first-child {
    left: 0;
  }
  ${media.middle`
		width: 30rem;
		max-width: 150px;
		height: 40px;
		font-size: 2.5em;
	`}
  ${media.small`
		width: 30rem;
		max-width: 150px;
		height: 30px;
		font-size: 2rem;
	`}
`;

const DetailArea = styled.div`
  width: 90%;
  margin: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.small`
		width: 100%;
		margin:0;
	`}

  & img {
    width: fit-content;
    display: block;
    max-width: 100%;
    text-align: center;
    margin: auto;
  }
`;

function DetailBody({ data }) {
  const [detailMenuOn, setDetailMenuOn] = useState("제품특징");
  const { server } = useContext(ProductContext);
  const onMenuClickHandler = (e) => {
    setDetailMenuOn(e.target.innerHTML);
  };

  return (
    <>
      <DetailMenuArea>
        <DetailMenu
          onClick={onMenuClickHandler}
          clicked={detailMenuOn === "제품특징"}
        >
          제품특징
        </DetailMenu>
        <DetailMenu
          onClick={onMenuClickHandler}
          clicked={detailMenuOn === "스펙"}
        >
          스펙
        </DetailMenu>
      </DetailMenuArea>
      <DetailArea>
        {detailMenuOn === "제품특징" &&
          data.productImages.map((img) => {
            return <img src={server + img} alt="detail" key={img} />;
          })}
        {detailMenuOn === "스펙" &&
          data.specImages.map((img) => {
            return (
              <div>
                <img src={server + img} alt="detail" key={img} />
              </div>
            );
          })}
      </DetailArea>
    </>
  );
}

export default DetailBody;
