import { ProductContext } from "contexts/ProductContext";
import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { media } from "style/media";
import styled from "styled-components";

function Card({ data }) {
  const imgUrl = data.mainImages[0];
  const { server } = useContext(ProductContext);
  return (
    <Wrapper>
      <Link to={"/detail"} state={data}>
        <CardImagearea imgUrl={server + imgUrl} />
        <CardTextarea>
          <CardTitle>{data.title}</CardTitle>
          <CardPrice>{data.price}</CardPrice>
        </CardTextarea>
      </Link>
    </Wrapper>
  );
}

export default Card;

const Wrapper = styled.div`
  width: 220px;
  height: 300px;
  border: 1px solid #999;
  /* margin: 20px 15px; */

  transition: all 0.25s;
  cursor: pointer;

  & * {
    margin: 0;
  }
  &:hover {
    transform: scale(1.02);
  }

  ${media.small`
		width: 135px;
		height: 195px;
	`}
`;

const CardImagearea = styled.div`
  width: 100%;
  height: 220px;
  background-image: url(${(props) => props.imgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;

  ${media.small`
		height: 135px;
	`}
`;

const CardTextarea = styled.div`
  width: 100%;
  text-align: center;
  & * {
    font-weight: 600;
  }
`;

const CardTitle = styled.span`
  font-size: 13px;
  margin: 10px auto 0;
  width: 95%;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${media.small`
		font-size: 11px;
		margin-top: 5px;
		height: 27px;
	`}
`;

const CardPrice = styled.span`
  font-size: 14px;
  display: block;
  margin-top: 10px;

  ${media.small`
		font-size: 12px;
		margin-top: 5px;
	`}
`;
