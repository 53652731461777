import { ProductContext } from "contexts/ProductContext";
import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import "./Carousel.css";

const ImageCarousel = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const carouselItemsRef = useRef([]);
  const { server } = useContext(ProductContext);

  const executeScroll = (newIdx) =>
    carouselItemsRef.current[newIdx].scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "center",
    });

  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        images.length
      );

      setSelectedImageIndex(0);
      setSelectedImage(images[0]);
    }
  }, [images]);

  const handleSelectedImageChange = (newIdx) => {
    if (images && images.length > 0) {
      setSelectedImageIndex(newIdx);
      setSelectedImage(images[newIdx]);
      if (carouselItemsRef?.current[newIdx]) {
        executeScroll(newIdx);
      }
    }
  };

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  return (
    <div className="carousel-container">
      <div
        className="selected-image"
        style={{
          backgroundImage: `url(${server + selectedImage})`,
          border: "1px solid #999",
        }}
      >
        <button
          className="carousel__button carousel__button-left"
          onClick={handleLeftClick}
        >
          <img src={process.env.PUBLIC_URL + "/images/prev.png"} alt="Prev" />
        </button>
        <button
          className="carousel__button carousel__button-right"
          onClick={handleRightClick}
        >
          <img src={process.env.PUBLIC_URL + "/images/next.png"} alt="Next" />
        </button>
      </div>
      <div className="carousel">
        <div className="carousel__images">
          {images &&
            images.map((image, idx) => (
              <div
                onClick={() => handleSelectedImageChange(idx)}
                style={{
                  backgroundImage: `url(${server + image})`,
                }}
                key={idx}
                className={`carousel__image ${
                  selectedImageIndex === idx && "carousel__image-selected"
                }`}
                ref={(el) => (carouselItemsRef.current[idx] = el)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
