import React, { useEffect } from "react";
const { daum } = window;

function Map() {
  useEffect(() => {
    new daum.roughmap.Lander({
      timestamp: "1680585241980",
      key: "2ebkx",
    }).render();
  }, []);

  return (
    <div
      id="daumRoughmapContainer1680585241980"
      className="root_daum_roughmap root_daum_roughmap_landing"
    ></div>
  );
}
export default Map;
