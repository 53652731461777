import React, { useState } from "react";
import styled from "styled-components";
import { Menu } from "antd";
import Search from "antd/es/input/Search";
import { media } from "style/media";
import { getSize } from "util/window";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getCategory } from "util/category/category";
import { useRef } from "react";
import Login from "Components/Login/Login";
import SideBanner from "Components/Banner/SideBanner";
import AdPop from "Components/Ad/AdPop";

const Wrapper = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 9rem;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  z-index: 9;

  box-shadow: 0 0px 20px #00000026;
  ${media.middle`
		box-shadow:none;
		flex-direction: column;
		min-height: 60px;
		height: 60px;
  `}
  ${media.small`
		box-shadow:none;	
		flex-direction: column;
		min-height: 50px;
		height: 50px;
  `}
`;

const Logoarea = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 9;
  ${media.middle`
		width: 100%;
		height: 60px;
		justify-content: space-between;
  `}
  ${media.small`
		width: 100%;
		height: 60px;
		justify-content: space-between;
  `}
`;

const Logo = styled.span`
  margin: 0 4rem;
  font-size: 30px;
  font-weight: bold;

  ${media.middle`
		font-size: 25px;
		margin: 0 10rem;
  `}

  ${media.small`
		font-size: 20px;
		margin: 0 10rem;
  `}
`;

const Toggle = styled.a`
  width: 20px;
  height: 20px;
  margin: 0 10rem;
  display: none;
  cursor: pointer;
  & img {
    width: 20px;
  }

  ${media.middle`
		display:block;
  `}
  ${media.small`
		display:block;
  `}
`;

const Menuarea = styled.div`
  width: 80%;
  background-color: #fff;
  transition: top 500ms;

  ${media.middle`
		width: 100%;
		height: 400px;
		margin:0;
		position: absolute;
		top: ${(props) => (props.toggleOn === false ? "-340px" : "60px")};
		box-shadow: 0 0px 20px #00000026;
		z-index: 7;
	`}
  ${media.small`
		width: 100%;
		height: 400px;
		margin:0;
		position: absolute;
		top: ${(props) => (props.toggleOn === false ? "-350px" : "50px")};
		box-shadow: 0 0px 20px #00000026;
	`}

  & ul {
    justify-content: center;
    border: none;
    min-width: 10rem;
    width: 100%;

    ${media.middle`
			flex-direction: column;
			align-items: center;
			width:100%;
		`}
    ${media.small`
			flex-direction: column;
			align-items: center;
			width:100%;
		`}
  }

  & ul li {
    font-size: 15px !important;
    text-align: center !important;
    padding: 0 !important;
    min-width: 105px !important;

    ${media.middle`
			width: 90% !important;
			font-size:15px !important;
			height:50px !important;
			margin:auto !important;
			line-height: 50px !important;
		`}
    ${media.small`
			width: 90% !important;
			font-size:15px !important;
			height:50px !important;
			margin:auto !important;
			line-height: 50px !important;
		`}
  }
  & ul li div {
    width: 100%;
  }
`;

const GoUpButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  border-color: #ccc;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: bold;
  border-right-color: #fff;
  border-bottom-color: #fff;
  ${media.middle`
		width: 40px;
  	height: 40px;
		font-size: 13px;
  `}
  ${media.small`
		width: 40px;
  	height: 40px;
		font-size: 13px;
  `}
	cursor:pointer;
`;

const listStyle = {
  width: "12rem",
  minWidth: "110px",
  display: "flex",
  justifyContent: "center",
  fontSize: "1.5rem",
  padding: "0 10px",
};

function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showPop, setShowpop] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const myRef = useRef(null);
  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });

  const items2 = () => {
    let itemList = [];

    getCategory().forEach((item) => {
      let childList = [];

      if (item.children) {
        Object.keys(item.children).forEach((child) => {
          childList.push({
            label: (
              <Link
                onClick={() => setToggleOn(false)}
                to={`/${item.key}`}
                state={`${item.children[child].key}`}
              >
                {item.children[child].label}
              </Link>
            ),
            key: `/${item.children[child].key}`,
          });
        });
      } else {
        childList = null;
      }

      itemList.push({
        label: item.children ? (
          <Link
            onClick={() => setToggleOn(false)}
            to={`/${item.key}`}
            state={`${item.default}`}
          >
            {item.label}
          </Link>
        ) : (
          <Link
            onClick={() => setToggleOn(false)}
            to={`/${item.key}`}
            state={`${item.key}`}
          >
            {item.label}
          </Link>
        ),
        key: `/${item.key}`,
        children: childList,
        style: listStyle,
      });
    });
    return itemList;
  };

  const location = useLocation();
  const [toggleOn, setToggleOn] = useState(false);

  const mode = getSize() === "large" ? "horizontal" : "vertical";

  const toggleOnClickHandler = () => {
    setToggleOn((prev) => !prev);
  };

  useEffect(() => {
    setToggleOn(false);
  }, [location]);

  return (
    <Wrapper ref={myRef}>
      <Logoarea>
        <Link to="/" state={"main"}>
          <Logo>KMobile</Logo>
        </Link>
        <Toggle onClick={toggleOnClickHandler}>
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${toggleOn ? "close" : "menu"}.png`
            }
            alt="M"
          />
        </Toggle>
      </Logoarea>
      <Menuarea toggleOn={toggleOn}>
        <Menu
          selectedKeys={[location.pathname]}
          mode={mode}
          items={items2()}
          style={{ minWidth: 0, flex: "auto" }}
        />
      </Menuarea>
      <Login />
      <GoUpButton onClick={executeScroll}>Top</GoUpButton>
      <SideBanner />
      {showPop && <AdPop setShowpop={setShowpop} />}
    </Wrapper>
  );
}

export default Header;
