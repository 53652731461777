import { Link } from "react-router-dom";
import styled from "styled-components";

const Background = styled.div`
  /* width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000007d; */

  z-index: 9990;
`;

const Wrapper = styled.div`
  width: 90vw;
  height: 95vw;
  max-width: 510px;
  max-height: 550px;
  position: fixed;
  top: 2%;
  left: 5%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: #fff;

  border: 1px solid #bbb;

  box-shadow: 0 0px 20px #00000026;

  & > * {
    cursor: pointer;
    transition: all 100ms;
  }

  /* main:has(&) {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  } */
`;

const ImageArea = styled.div`
  width: 98%;
  height: 100%;
  margin-top: 10px;
  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  & * {
    transition: all 80ms;
  }

  &:hover img {
    filter: brightness(0.65);
  }

  &:hover button {
    opacity: 0.8;
  }

  &:hover button:hover {
    opacity: 1;
  }

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > a > img {
    width: 95%;
    /* height: 700px; */
  }
`;

const NotToday = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.5em;
  padding: 0 20px;

  & > span:hover {
    color: #000;
    font-weight: bold;
  }
`;

const Go = styled.button`
  width: 120px;
  height: 60px;
  border-radius: 30px;
  position: absolute;
  opacity: 0;
  font-weight: bold;
  font-size: 20px;
  background: none;
  color: #fff;
  border: 3px solid #fff;

  cursor: pointer;
`;

function AdPop({ setShowpop }) {
  const closeButtonHandler = () => {
    setShowpop(false);
  };

  return (
    <Background>
      <Wrapper>
        <ImageArea onClick={closeButtonHandler}>
          <Link to={"/cs?64914db90f0735a684c09dca"} state={"notice"}>
            <img
              src={process.env.PUBLIC_URL + "/images/popup/popup.jpg"}
              alt="no"
            />
            <Go>이동하기</Go>
          </Link>
        </ImageArea>
        <NotToday onClick={closeButtonHandler}>
          <span>닫기</span>
        </NotToday>
      </Wrapper>
    </Background>
  );
}

export default AdPop;
