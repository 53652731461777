import TextArea from "Components/Input/TextArea";
import TextBar from "Components/Input/TextBar";
import Wrapper from "Components/Wrapper/Wrapper";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { media } from "style/media";
import styled from "styled-components";
import { insertNotice } from "_actions/notice_action";

const Body = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const Required = styled.span`
  color: red;
  margin: 0 10px;
  &:after {
    content: "*";
  }
`;

const Text = styled.span`
  color: #333;
  font-size: 16px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: none;
  font-size: 16px;
  width: 100%;
  margin: 0 0 50px 0;
  word-break: keep-all;

  & tr {
    border-bottom: 1px solid #999;
  }

  & tr {
    cursor: pointer;
  }

  & tr th {
    width: 4%;
    height: 70px;
  }

  & tr td:last-child {
    width: 75%;
    height: 70px;
  }

  ${media.small`
		font-size: 12px;
  `}
`;

const TitleBR = styled.div`
  width: 100%;
  height: 13px;
  border-bottom: 2px solid #333;
`;

const Confirm = styled.button`
  display: block;
  width: 80px;
  height: 30px;
  background-color: #8cc7d7;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  margin: 0 20px 50px;
  border-radius: 5px;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #58b9d3;
  }
`;

const Cancel = styled.button`
  display: block;
  width: 80px;
  height: 30px;
  background-color: #d38d8d;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  margin: 0 20px 50px;
  border-radius: 5px;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #d35858;
  }
`;

const Agree = styled.span`
  display: block;
  font-size: 14px;
  float: right;
`;

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

function Write({ setIsWrite, getList }) {
  const [data, setData] = useState({
    writer: "KMobile",
    title: "",
    contents: "",
  });

  const dispatch = useDispatch();

  const onConfiemButtonHandler = () => {
    if (data.title === "" || data.contents === "") {
      alert("필수 입력란을 작성 해주세요.");
      return;
    }

    // return;
    dispatch(insertNotice(data)).then((res) => {
      alert(res.payload.message);
      if (res.payload.success) {
        getList();
        setIsWrite(false);
      }
    });
  };
  const onCancelButtonHandler = () => {
    setIsWrite(false);
  };

  return (
    <>
      <Wrapper>
        <Body>
          <Text>
            <Required />
            필수 입력사항 입니다.
          </Text>
          <TitleBR />
        </Body>
        <Table>
          <tbody>
            <tr>
              <th>
                <Required />
              </th>
              <td>제목 </td>
              <td>
                <TextBar
                  valName={"title"}
                  setData={setData}
                  data={data}
                  placeholder={"제목을 입력 해주세요"}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Required />
              </th>
              <td>공지 내용</td>
              <td>
                <TextArea
                  valName={"contents"}
                  setData={setData}
                  data={data}
                  placeholder={"내용을 입력해주세요"}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Agree></Agree>
        <ButtonArea>
          <Cancel onClick={onCancelButtonHandler}>취소</Cancel>
          <Confirm onClick={onConfiemButtonHandler}>확인</Confirm>
        </ButtonArea>
      </Wrapper>
    </>
  );
}

export default Write;
