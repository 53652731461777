const { sizes } = require("style/media");

const getSize = () => {
  const size = window.visualViewport.width;
  if (size <= sizes.small) return "small";
  if (sizes.small < size && size < sizes.middle) return "middle";
  if (sizes.middle <= size) return "large";
};

module.exports = { getSize };
