import Cards from "Components/Cards/Cards";
import Wrapper from "Components/Wrapper/Wrapper";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBestProducts } from "_actions/product_action";

function Main() {
  const dispatch = useDispatch();

  const [initProduct, setInitProduct] = useState(false);
  const [monthList, setMonthList] = useState([]);
  const [popularList, setPopularList] = useState([]);
  const [bestList, setBestList] = useState([]);

  useEffect(() => {
    dispatch(
      getBestProducts({
        query: { isMonthBest: "1" },
      })
    ).then((res) => {
      if (res.payload.success) {
        setMonthList(res.payload.data);
      }
    });

    dispatch(
      getBestProducts({
        query: { isPopular: "1" },
      })
    ).then((res) => {
      if (res.payload.success) {
        setPopularList(res.payload.data);
      }
    });

    dispatch(
      getBestProducts({
        query: { isBest: "1" },
      })
    ).then((res) => {
      if (res.payload.success) {
        setBestList(res.payload.data);
        setInitProduct(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {initProduct ? (
        <>
          <Cards title={"이 달의 상품"} list={monthList} />
          <Cards title={"인기 상품"} list={popularList} />
          <Cards title={"추천 상품"} list={bestList} />
        </>
      ) : (
        <>Loading... </>
      )}
    </Wrapper>
  );
}

export default Main;
