const pc = [
  "CPU",
  "OS",
  "RAM",
  "디스플레이 크기",
  "모델명",
  "무게",
  "밝기",
  "베젤두께",
  "사이즈 (가로X세로X높이)",
  "색상",
  "원산지",
  "인증번호",
  "저장공간",
  "전력소비",
  "제조사",
  "주요특징",
  "터치스크린",
  "픽셀피치",
  "해상도",
];
const display = [
  "디스플레이 크기",
  "모델명",
  "무게",
  "밝기",
  "베젤두께",
  "사이즈 (가로X세로X높이)",
  "색상",
  "설치형태",
  "원산지",
  "인증번호",
  "전력소비",
  "제조사",
  "주요특징",
  "카메라",
  "픽셀피치",
  "해상도",
];
const kitchen = [
  "냉동실 용량",
  "냉장실 용량",
  "모델명",
  "무게",
  "사이즈 (가로X세로X높이)",
  "색상",
  "소비전력",
  "소비효율등급",
  "용량",
  "원산지",
  "인증번호",
  "전체용량",
  "제조사",
  "주요특징",
  "출력",
];
const live = [
  "모델명",
  "무게",
  "사이즈 (가로X세로X높이)",
  "색상",
  "소비전력",
  "소비효율등급",
  "악세사리",
  "용량",
  "원산지",
  "인증번호",
  "제조사",
  "주요특징",
  "최대소음",
  "필터",
  "흡입력",
];
const mobile = [
  "모델명",
  "무게",
  "사이즈 (가로X세로X높이)",
  "색상",
  "소비전력",
  "소비효율등급",
  "수명",
  "악세사리",
  "원산지",
  "인증번호",
  "제조사",
  "주요특징",
];

const arrToLayout = (arr) => {
  const obj = {};
  arr.forEach((str, idx) => {
    obj[str] = { str: idx };
  });
  return obj;
};

export const layoutArr = {
  pc: arrToLayout(pc),
  display: arrToLayout(display),
  kitchen: arrToLayout(kitchen),
  live: arrToLayout(live),
  mobile: arrToLayout(mobile),
};

export const layoutObj = {
  pc: pc,
  display: display,
  kitchen: kitchen,
  live: live,
  mobile: mobile,
};
