/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import Display from "./Display/Display";
import { FuroProvider } from "furo-react";
import { key } from "util/login/auth";
import { ProductProvider } from "contexts/ProductContext";
import Loading from "Components/Loading/Loading";
import { useDispatch } from "react-redux";
import { auth } from "_actions/user_action";

function App() {
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  //loading spiner
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //axios 호출시 인터셉트
    axios.interceptors.request.use(
      function (config) {
        if (config.url.includes("detection")) {
          setLoading(true);
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    //axios 호출 종료시 인터셉트
    axios.interceptors.response.use(
      function (response) {
        setLoading(false);
        return response;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);
  useEffect(() => {
    setInit(true);
    dispatch(auth()).then((res) => {
      console.log(res);
    });
  }, [Display]);

  const theme = {
    token: {
      colorPrimary: "#1890ff",
      colorPrimaryBg: "#cbe6ff",
    },
  };

  return (
    <FuroProvider
      domain="https://auth.furo.one"
      clientId={key}
      redirectUri={window.location.origin}
    >
      <ConfigProvider theme={theme}>
        <ProductProvider>
          <>
            {init ? (
              <Router>
                <Display />
              </Router>
            ) : (
              "Initializing..."
            )}
            <Loading loading={loading} />
          </>
        </ProductProvider>
      </ConfigProvider>
    </FuroProvider>
  );
}

export default App;
