import { useFuro } from "furo-react";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { media } from "style/media";
import styled from "styled-components";
import { replyQna } from "_actions/qna_action";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  display: flex;

  justify-content: center;
  align-items: center;
  background-color: #00000099;
`;

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const Table = styled.table`
  background-color: #fff;
  border-collapse: collapse;
  border: none;
  font-size: 16px;
  width: 1000px;

  height: 600px;
  border-radius: 20px;
  overflow: hidden;

  word-break: keep-all;
  z-index: 11;
  & tr {
    border-bottom: 1px solid #999;
  }

  & tr td:first-child,
  td:nth-child(3) {
    width: 15%;
    text-align: center;

    padding: 20px 0;

    font-weight: 600;
    background-color: #eee;
  }

  & tr td:nth-child(2),
  td:nth-child(4) {
    padding-left: 30px;
    width: 35%;
  }

  ${media.middle`
		width: 80%;
		min-width: 550px;
  `}

  ${media.small`
		width: 90%;
		min-width: 300px;
		font-size: 12px;
  `}

  & tr:nth-child(2) {
    height: 68%;
  }

  & tr:nth-child(3) {
    height: 20%;
  }
`;

const WriteButton = styled.button`
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  vertical-align: middle;
  background-color: #8cc7d7;
  cursor: pointer;
  margin-left: 0;
  border-radius: 4px;
  border: none;
  font-size: 14px;
`;
const InputText = styled.textarea`
  width: 95%;
  border: 1px solid #999;
  border-radius: 4px;
  height: 100px;
  margin: 20px 0;
  padding: 10px;
`;

function Detail({ data, setShowDetail, getList }) {
  const { user } = useFuro();
  const dispatch = useDispatch();

  const [replyText, setReplyText] = useState(data.reply);

  const onBackgroundClickHandler = () => {
    setShowDetail(<></>);
  };

  const onWriteButtonClickHandler = () => {
    const reply = data;
    reply.reply = replyText;

    dispatch(replyQna(reply)).then((res) => {
      alert(res.payload.message);
      if (res.payload.success) {
        setShowDetail(<></>);
        getList();
        // navigate(0);
      }
    });
  };

  return (
    <Wrapper>
      <Background onClick={onBackgroundClickHandler} />
      <Table>
        <tbody>
          <tr>
            <td>제목</td>
            <td>{data.title}</td>
            <td>작성자</td>
            <td>{data.writer}</td>
          </tr>
          <tr>
            <td>내용</td>
            <td colSpan={3} style={{ height: "300px" }}>
              {data.contents}
            </td>
          </tr>
          {user ? (
            <tr>
              <td>답변</td>
              <td colSpan={2}>
                <InputText
                  value={replyText}
                  type="text"
                  onChange={(e) => setReplyText(e.target.value)}
                />
              </td>
              <td>
                <WriteButton onClick={onWriteButtonClickHandler}>
                  답변달기
                </WriteButton>
              </td>
            </tr>
          ) : (
            <tr>
              <td>답변</td>
              <td colSpan={3}>{data.reply}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Wrapper>
  );
}

export default Detail;
