import axios from "axios";
import {
  GET_PRODUCT,
  GET_PRODUCTS,
  WRITE_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  BEST_PRODUCT,
} from "./types";

export function getProduct(dataToSubmit) {
  const request = axios
    .post("/api/product/get", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_PRODUCT,
    payload: request,
  };
}

export function getProducts(dataToSubmit) {
  const request = axios
    .post("/api/product/list", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_PRODUCTS,
    payload: request,
  };
}

export function insertProduct(dataToSubmit) {
  const request = axios
    .post("/api/product/insert", dataToSubmit)
    .then((response) => response.data);

  return {
    type: WRITE_PRODUCT,
    payload: request,
  };
}

export function deleteProduct(dataToSubmit) {
  const request = axios
    .post("/api/product/delete", dataToSubmit)
    .then((response) => response.data);

  return {
    type: DELETE_PRODUCT,
    payload: request,
  };
}

export function updateProduct(dataToSubmit) {
  const request = axios
    .post("/api/product/update", dataToSubmit)
    .then((response) => response.data);

  return {
    type: UPDATE_PRODUCT,
    payload: request,
  };
}

export function clickBestProduct(dataToSubmit) {
  const request = axios
    .post("/api/product/best", dataToSubmit)
    .then((response) => response.data);

  return {
    type: BEST_PRODUCT,
    payload: request,
  };
}

export function getBestProducts(dataToSubmit) {
  const request = axios
    .post("/api/product/bestlist", dataToSubmit)
    .then((response) => response.data);

  return {
    type: BEST_PRODUCT,
    payload: request,
  };
}
