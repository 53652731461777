import DetailInputText from "Components/Input/DetailInputText";
import DetailKeyInputText from "Components/Input/DetailKeyInputText";
import React from "react";
import { useEffect } from "react";
// import { useState } from "react";
import styled from "styled-components";
import { layoutObj } from "util/category/layout";

const Ul = styled.ul`
  width: 100%;
  list-style: none;
`;

const Li = styled.li`
  width: 100%;
  /* height: 20px; */
  display: flex;
`;

const InputArea = styled.div`
  width: 65%;
  height: 40px;
`;

const ListNum = styled.div`
  display: flex;
  width: 3%;
  height: 40px;
  justify-content: center;
  align-items: center;

  color: #999;
  font-size: 12px;
  /* line-height: 140%; */
`;

const ListDel = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;

  color: #999;
  font-size: 12px;
  cursor: pointer;
  background-color: #333;
  border-radius: 20px;
  margin: 10px 5px;
  /* line-height: 140%; */
`;

function Detail({ detail, setDetail, mainCategory, state }) {
  const layoutInit = () => {
    let arr = [];
    if (mainCategory === "") return [];

    for (let idx = 0; idx < 20; idx++) {
      const key = layoutObj[mainCategory][idx] || "";
      arr.push({ idx: idx, key: key, value: "" });
    }

    // layoutObj[mainCategory].forEach((data, idx) => {
    //   arr.push({ idx: idx, key: data, value: "" });
    // });

    setDetail(arr);
  };
  const updateLayoutInit = () => {
    let arr = [];

    for (let idx = 0; idx < 20; idx++) {
      arr.push({
        idx: detail[idx].idx,
        key: detail[idx].key,
        value: detail[idx].value,
      });
    }

    // layoutObj[mainCategory].forEach((data, idx) => {
    //   arr.push({ idx: idx, key: data, value: "" });
    // });

    setDetail(arr);
  };

  useEffect(() => {
    layoutInit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCategory]);

  useEffect(() => {
    if (!state) {
      layoutInit();
    } else {
      updateLayoutInit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const KeyInputHandler = (target) => {
    const idx = target.dataset.idx;

    setDetail((prev) => {
      let arr = prev;
      arr[idx] = { idx: idx, key: target.value, value: arr[idx].value };
      return arr;
    });
  };

  const InputHandler = (target) => {
    const idx = target.dataset.idx;

    setDetail((prev) => {
      let arr = prev;
      arr[idx] = { idx: idx, key: arr[idx].key, value: target.value };
      return arr;
    });
  };

  const onListDelButtonClickHandler = (data) => {
    setDetail((prev) => {
      let arr = prev;
      arr[data.idx] = { idx: data.idx, key: "", value: "" };
      return arr;
    });
    document.getElementById("key_" + data.idx).value = "";
    document.getElementById("value_" + data.idx).value = "";
  };

  const list = detail?.map((data, idx) => (
    <Li key={idx}>
      <ListNum>{idx + 1}</ListNum>
      <ListDel onClick={() => onListDelButtonClickHandler(data)}>x</ListDel>
      <DetailKeyInputText
        setData={KeyInputHandler}
        data={{ set: data.key, idx: idx }}
      />
      <InputArea>
        <DetailInputText
          setData={InputHandler}
          data={{ idx: idx, value: data.value }}
        />
      </InputArea>
    </Li>
  ));

  return (
    <>
      <Ul>{list}</Ul>
      {/* <AddButton onClick={addButtonHandler}>추가하기</AddButton> */}
    </>
  );
}

export default Detail;
