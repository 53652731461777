import styled from "styled-components";

const SelectBox = styled.select`
  width: 47.5%;
  height: 40px;
  margin: 15px 0;
  border: 1px solid #999;
  border-radius: 4px;
  padding-left: 10px;
`;

function Select({ children, onChange, defaultValue }) {
  return (
    <SelectBox onChange={onChange} defaultValue={defaultValue}>
      {children}
    </SelectBox>
  );
}

export default Select;
