import SubMenu from "Components/SubMenu/SubMenu";
import Wrapper from "Components/Wrapper/Wrapper";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { categoryObj } from "util/category/category";
import List from "./List";

function Product() {
  const location = useLocation();
  const key = location.pathname.replace("/", "");
  const [menu, setMenu] = useState(
    location.state ? location.state : categoryObj[key].default
  );
  const category = categoryObj[key];

  const subMenu = {
    location: location,
    now: menu,
    submenu: category.children,
    setMenu: setMenu,
  };

  useEffect(() => {
    setMenu(location.state);
  }, [location]);

  return (
    <>
      <SubMenu subMenu={subMenu} />
      <Wrapper>
        <List category={category} menu={menu} />
      </Wrapper>
    </>
  );
}

export default Product;
