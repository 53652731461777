import ScrollRevealSlideAnimation from "Components/Animator/ScrollRevealSlideAnimation";
import Map from "Components/Kakao/Map";
import Wrapper from "Components/Wrapper/Wrapper";
import { media } from "style/media";
import styled from "styled-components";

const IntroTitle = styled.h2`
  font-size: 28px;
  margin-top: 100px;
  margin-bottom: 80px;
  text-align: center;

  ${media.middle`
		font-size: 21px;
		margin-top: 100px;
		margin-bottom: 40px;
  `}
  ${media.small`
		font-size: 15px;
		margin-top: 80px;
		margin-bottom: 0px;
  `}
`;

const ContentRow = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  word-break: keep-all;

  ${media.middle`
		height: 130px;
	`}
  ${media.small`
		height: 120px;
	`}

	& p {
    width: 100%;
    ${media.middle`
			font-size: 16px;
		`}
    ${media.small`
			font-size: 12px;
		`}
  }
`;

const ContentTextCenter = styled.p`
  font-size: 21px;
  text-align: center;
  ${media.middle`
		font-size: 18px;
	`}
  ${media.small`
		font-size: 12px;
	`}
`;

// const ContentImg = styled.img`
//   width: 300px;
//   height: 300px;
//   border: 1px solid #999;

//   ${media.middle`
// 		width: 250px;
// 		height: 250px;
// 	`}
//   ${media.small`
// 		width: 200px;
// 		height: 200px;
// 	`}
// `;

const SubTitle = styled.h2`
  font-size: 28px;
  margin-top: 240px;
  margin-bottom: 140px;
  text-align: center;

  ${media.middle`
		font-size: 21px;
		margin-top: 240px;
		margin-bottom: 80px;
  `}
  ${media.small`
		font-size: 21px;
		margin-top: 150px;
		margin-bottom: 50px;
  `}
`;

const ProcessArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & img {
    width: 100%;
    max-width: 700px;
    ${media.middle`
			width: 60%;
			max-width: 400px;
		`}
    ${media.small`
			width: 100%;
			max-width: 320px;
		`}
  }
`;

// const Process = styled.div`
//   width: 150px;
//   aspect-ratio: auto 1 / 1;

//   border: 1px solid #999;
//   border-radius: 50%;

//   text-align: center;
//   margin: 0 30px 20px;

//   background-image: url(https://api.kmobileb2b.com${(props) => props.img});
//   background-size: 45%;
//   background-repeat: no-repeat;
//   background-position: center;

//   & span {
//     font-size: 18px;
//     position: relative;
//     top: 110%;
//     font-weight: bold;
//     word-break: keep-all;
//   }

//   ${media.middle`
// 		width: 120px;
// 		margin: 0 20px 20px;
// 		& span {
// 			font-size: 18px;
// 		}
// 	`}
//   ${media.small`
// 		width: 100px;
// 		min-width: 70px;
// 		margin: 0 5px 20px;
// 		& span {
// 			font-size: 14px;
// 		}
// 	`}
// `;

const MapArea = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
`;

function Introduce() {
  return (
    <>
      <Wrapper width={900}>
        <IntroTitle>케이모바일은,</IntroTitle>
        <ContentRow>
          <ScrollRevealSlideAnimation direction={"bottom"} time={"1s"}>
            <ContentTextCenter>
              삼성전자 B2B제품의 유통을 전문으로 하는 삼성 공식 파트너로,
              <br />
              생활가전 및 디스플레이, PC등 다양한 제품군을 제공하고 있습니다.
            </ContentTextCenter>
          </ScrollRevealSlideAnimation>
        </ContentRow>

        <ContentRow>
          <ScrollRevealSlideAnimation direction={"bottom"} time={"1s"}>
            <ContentTextCenter>
              고객 비즈니스 사은품부터 관공서, 오피스텔, 교육기관까지
              <br />
              전자제품이 필요로 하는 모든 영업장에서 양질의 제품과 서비스를
              제공하여
              <br />
              높은 고객 만족도를 추구하고 있습니다.
            </ContentTextCenter>
          </ScrollRevealSlideAnimation>
        </ContentRow>

        <ContentRow>
          <ScrollRevealSlideAnimation direction={"bottom"} time={"1s"}>
            <ContentTextCenter>
              언제나 깊은 신뢰와 감동을 드릴 것을 약속드리며
              <br />
              끊임없이 노력하여 발전하는 모습으로 보답하겠습니다.
              <br /> <br /> <br />
              감사합니다.
            </ContentTextCenter>
          </ScrollRevealSlideAnimation>
          {/* <ContentImg /> */}
        </ContentRow>
        <br />
        <br />

        <ScrollRevealSlideAnimation direction={"bottom"} time={"1s"}>
          <ContentTextCenter>KMOBILE 직원 일동</ContentTextCenter>
        </ScrollRevealSlideAnimation>

        <ScrollRevealSlideAnimation direction={"bottom"} time={"1s"}>
          <SubTitle>제품 제공 과정</SubTitle>
        </ScrollRevealSlideAnimation>
        <ScrollRevealSlideAnimation direction={"bottom"} time={"1s"}>
          <ProcessArea>
            <img
              src={process.env.PUBLIC_URL + "/images/introduce/process.png"}
              alt="no"
            />
          </ProcessArea>
        </ScrollRevealSlideAnimation>
        <ScrollRevealSlideAnimation direction={"bottom"} time={"1s"}>
          <SubTitle>오시는 길</SubTitle>
          <MapArea>
            <Map />
          </MapArea>
        </ScrollRevealSlideAnimation>
      </Wrapper>
    </>
  );
}

export default Introduce;
