import React from "react";
import { media } from "style/media";
import styled from "styled-components";

function Wrapper({ children, width }) {
  return <Div style={{ maxWidth: width }}>{children}</Div>;
}

export default Wrapper;

const Div = styled.div`
  margin: 60px auto 0;
  width: 140rem;
  max-width: 1200px;
  ${media.middle`
		width: 90%;
  `}
  ${media.small`
		width: 90%;
  `}
`;
