import Title from "Components/Title/Title";
import { media } from "style/media";
import styled from "styled-components";
// import { mock } from "util/mock/data";
import Card from "./Card/Card";

function Cards({ title, list }) {
  // const data = mock;

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <CardArea>
        {list.map((data) => (
          <Card data={data} key={data._id} />
        ))}
      </CardArea>
    </Wrapper>
  );
}

export default Cards;

const Wrapper = styled.div`
  margin: 50px auto 0;
  text-align: center;
`;

const CardArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;

  margin-top: 5rem;

  ${media.small`
	gap: 20px 10px;
	`}
`;
