const bannerText = {
  main: {
    title: "KMobile",
    sub: "비즈니스 고객을 위한 파트너",
  },
  introduce: {
    title: "KMobile",
    sub: "KMobile의 회사 소개 페이지입니다",
  },
  desktop: {
    title: "데스크탑",
    sub: "PC - 데스크탑",
  },
  notebook: {
    title: "노트북",
    sub: "PC - 노트북",
  },
  one: {
    title: "일체형PC",
    sub: "PC - 일체형PC",
  },
  pcacc: {
    title: "악세사리",
    sub: "PC - 악세사리",
  },
  ledM: {
    title: "LED모니터",
    sub: "디스플레이 - LED모니터",
  },
  curvedM: {
    title: "커브드모니터",
    sub: "디스플레이 - 커브드모니터",
  },
  businessT: {
    title: "비즈니스TV",
    sub: "디스플레이 - 비즈니스TV",
  },
  ledT: {
    title: "LEDTV",
    sub: "디스플레이 - LEDTV",
  },
  extraT: {
    title: "초대형TV",
    sub: "디스플레이 - 초대형TV",
  },
  displayacc: {
    title: "악세사리",
    sub: "디스플레이 - 악세사리",
  },
  // refrig: {
  //   title: "냉장고/김치냉장고",
  //   sub: "주방가전 - 냉장고/김치냉장고",
  // },
  // microwave: {
  //   title: "전자레인지/전기레인지",
  //   sub: "주방가전 - 전자레인지/전기레인지",
  // },
  // oven: {
  //   title: "오븐/쿠커",
  //   sub: "주방가전 - 오븐/쿠커",
  // },
  // dish: {
  //   title: "식기세척기",
  //   sub: "주방가전 - 식기세척기",
  // },
  // kitchenacc: {
  //   title: "악세사리",
  //   sub: "주방가전 - 악세사리",
  // },
  // vacuum: {
  //   title: "청소기",
  //   sub: "생활가전 - 청소기",
  // },
  // airdresser: {
  //   title: "에어드레서",
  //   sub: "생활가전 - 에어드레서",
  // },
  // air: {
  //   title: "공기청정기",
  //   sub: "생활가전 - 공기청정기",
  // },
  // liveacc: {
  //   title: "악세사리",
  //   sub: "생활가전 - 악세사리",
  // },

  air: {
    title: "공기청정기",
    sub: "가전 - 공기청정기",
  },
  vacuum: {
    title: "청소기",
    sub: "가전 - 청소기",
  },
  refrig: {
    title: "냉장고",
    sub: "가전 - 냉장고",
  },
  microwave: {
    title: "전자레인지/전기레인지",
    sub: "가전 - 전자레인지/전기레인지",
  },
  oven: {
    title: "오븐/쿠커",
    sub: "가전 - 오븐/쿠커",
  },
  dish: {
    title: "식기세척기",
    sub: "가전 - 식기세척기",
  },
  airconditioner: {
    title: "냉난방기",
    sub: "가전 - 냉난방기",
  },
  meet: {
    title: "회의실",
    sub: "가전 - 회의실",
  },
  office: {
    title: "사무실",
    sub: "가전 - 사무실",
  },
  study: {
    title: "스터디룸",
    sub: "가전 - 스터디룸",
  },
  accom: {
    title: "숙박업소",
    sub: "가전 - 숙박업소",
  },
  cafe: {
    title: "카페",
    sub: "가전 - 카페",
  },

  phone: {
    title: "자급제폰",
    sub: "모바일 - 자급제폰",
  },
  tablet: {
    title: "태블릿",
    sub: "모바일 - 태블릿",
  },
  wear: {
    title: "웨어러블",
    sub: "모바일 - 웨어러블",
  },
  mobileacc: {
    title: "악세사리",
    sub: "모바일 - 악세사리",
  },
  estimate: {
    title: "견적문의",
    sub: "KMobile의 견적문의 페이지입니다",
  },
  notice: {
    title: "공지사항",
    sub: "KMobile의 공지사항 페이지입니다",
  },
  qna: {
    title: "1:1문의",
    sub: "KMobile의 1:1문의 페이지입니다",
  },
};

export const getBannerdata = (state, custom) => {
  if (custom) return custom;
  return bannerText[state];
};
