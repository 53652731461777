import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { categoryObj, getCategoryQuery } from "util/category/category";
import {
  clickBestProduct,
  getProducts,
  deleteProduct,
} from "_actions/product_action";
import AdminCategory from "./Form/AdminCategory";
import Sort from "./Form/Sort";

const Table = styled.table`
  border-collapse: collapse;
  border: none;
  font-size: 16px;
  width: 100%;
  margin: 0 0 50px 0;
  word-break: keep-all;

  & tr {
    border-bottom: 1px solid #999;
  }

  & thead td,
  th {
    border: 1px solid #999;
    text-align: center;
  }

  & td {
    text-align: center;
    padding: 2px;
  }

  & thead td {
    width: 10%;
  }

  & tr th:first-child {
    width: 5%;
  }

  & tr th:nth-child(2) {
    width: 20%;
  }

  & tr th:nth-child(3) {
    width: 25%;
  }

  & tr th:nth-child(4) {
    width: 25%;
  }

  & tr th:nth-child(5) {
    width: 5%;
  }

  & tr th:nth-child(6) {
    width: 5%;
  }
`;

const Delete = styled.button`
  width: 60px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
    border: 1px solid #333;
  }
`;

function ListProduct() {
  const dispatch = useDispatch();

  const [initProduct, setInitProduct] = useState(false);
  const [list, setList] = useState([]);
  const [sort, setSort] = useState("-_id");
  const [mainCategory, setMainCategory] = useState("all");
  const [subCategory, setSubCategory] = useState("all");

  useEffect(() => {
    const body = {
      query: { category: getCategoryQuery(mainCategory, subCategory) },
      sort: sort,
    };

    dispatch(getProducts(body)).then((res) => {
      if (res.payload.success) {
        setList(res.payload.data);
        setInitProduct(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCategory, subCategory, sort, list]);

  const onBestButtonClickHandler = (e) => {
    const target = e.target.name;

    const value = e.target.checked ? "1" : "0";

    const body = { _id: e.target.id, target: target, value: value };
    dispatch(clickBestProduct(body)).then((res) => {
      console.log(res.payload);
    });
  };

  const onDeleteButtonClickHandler = (e) => {
    const question = `${e.target.dataset.title}상품을 삭제 하시겠습니까?`;
    const checker = window.confirm(question);

    if (!checker) return;
    setInitProduct(false);

    const body = { _id: e.target.dataset.id };

    dispatch(deleteProduct(body)).then((res) => {
      alert("삭제 되었습니다");
    });
  };

  return (
    <>
      <AdminCategory
        mainCategory={mainCategory}
        setMainCategory={setMainCategory}
        setSubCategory={setSubCategory}
      />
      <Sort setSort={setSort} />
      {initProduct ? (
        <Table>
          <thead>
            <tr>
              <th rowSpan={2}>No</th>
              <th colSpan={2}>카테고리</th>
              <th rowSpan={2}>상품명</th>
              <th rowSpan={2}>가격</th>
              <th rowSpan={2}>이달의 추천</th>
              <th rowSpan={2}>인기 상품</th>
              <th rowSpan={2}>추천 상품</th>
              <th rowSpan={2}>삭제</th>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>대분류</td>
              <td style={{ fontWeight: "bold" }}>소분류</td>
            </tr>
          </thead>
          <tbody>
            {list.map((data, idx) => {
              return (
                <tr key={data._id}>
                  <td>{idx + 1}</td>
                  <td>{categoryObj[data.category.main].label}</td>
                  <td>
                    {
                      categoryObj[data.category.main].children[
                        data.category.sub
                      ].label
                    }
                  </td>
                  <td>{data.title}</td>
                  <td>{data.price}</td>
                  <td>
                    <input
                      defaultChecked={data.isMonthBest === "1"}
                      type="checkbox"
                      name="isMonthBest"
                      id={data._id}
                      onChange={onBestButtonClickHandler}
                    />
                  </td>
                  <td>
                    <input
                      defaultChecked={data.isPopular === "1"}
                      type="checkbox"
                      name="isPopular"
                      id={data._id}
                      onChange={onBestButtonClickHandler}
                    />
                  </td>
                  <td>
                    <input
                      defaultChecked={data.isBest === "1"}
                      type="checkbox"
                      name="isBest"
                      id={data._id}
                      onChange={onBestButtonClickHandler}
                    />
                  </td>
                  <td>
                    <Delete
                      onClick={onDeleteButtonClickHandler}
                      data-id={data._id}
                      data-title={data.title}
                    >
                      삭제
                    </Delete>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <>Loading... </>
      )}
    </>
  );
}

export default ListProduct;
