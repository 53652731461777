import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Breadcrumb } from "antd";
import Cards from "Components/Cards/Cards";
import styled from "styled-components";
import { getProducts } from "_actions/product_action";
import { categoryObj } from "util/category/category";

const Body = styled.div`
  width: 100%;
  margin-top: 30px;
`;

function List({ category }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const key = location.pathname.replace("/", "");
  const menu = location.state ? location.state : categoryObj[key].default;

  const [initProduct, setInitProduct] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    // //임시저장 제외 검색
    const query = {
      query: {
        category: {
          $eq: { main: category.key, sub: menu },
        },
      },
      sort: "-_id",
    };

    dispatch(getProducts(query)).then((res) => {
      if (res.payload.success) {
        setList(res.payload.data);
        setInitProduct(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  return (
    <Body>
      <Breadcrumb
        items={[
          { title: "홈" },
          { title: category.label },
          { title: category.children[menu].label },
        ]}
      />
      {initProduct ? <Cards list={list} /> : <>Loading... </>}
    </Body>
  );
}

export default List;
