import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getEstimates, repliedEstimate } from "_actions/estimate_action";

const Table = styled.table`
  border-collapse: collapse;
  border: none;
  font-size: 16px;
  width: 100%;
  margin: 0 0 50px 0;
  word-break: keep-all;

  & tr {
    border-bottom: 1px solid #999;
  }

  & thead th {
    border: 1px solid #999;
    text-align: center;
  }

  & td {
    text-align: center;
    padding: 2px;
    font-size: 14px;
  }

  & tr td:first-child {
    width: 5%;
  }

  & tr td:nth-child(2) {
    width: 15%;
  }

  & tr td:nth-child(3) {
    width: 15%;
  }

  & tr td:nth-child(4) {
    width: 15%;
  }

  & tr td:nth-child(5) {
    width: 15%;
  }

  & tr td:nth-child(8) {
    width: 5%;
  }
`;

function ListEstimate() {
  const dispatch = useDispatch();

  const [initProduct, setInitProduct] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    const body = {
      sort: "regDate, replied",
    };

    dispatch(getEstimates(body)).then((res) => {
      if (res.payload.success) {
        setList(res.payload.data);
        setInitProduct(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRepliedButtonClickHandler = (e) => {
    console.log(e.target.checked);
    const replied = e.target.checked ? "1" : "0";

    const body = { _id: e.target.id, replied: replied };
    dispatch(repliedEstimate(body)).then((res) => {
      if (res.payload.success) {
        const message = `${
          res.payload.replied === "1" ? "답변 완료" : "미답변"
        }처리 되었습니다.`;
        alert(message);
      }
    });
  };

  return (
    <>
      {initProduct ? (
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>작성자명</th>
              <th>회사명</th>
              <th>연락처</th>
              <th>이메일</th>
              <th>견적문의</th>
              <th>작성일자</th>
              <th>답변</th>
            </tr>
          </thead>
          <tbody>
            {list.map((data, idx) => {
              return (
                <tr key={data._id}>
                  <td>{idx + 1}</td>
                  <td>{data.name}</td>
                  <td>{data.company}</td>
                  <td>{data.phone}</td>
                  <td>{data.email}</td>
                  <td>{data.text}</td>
                  <td>{data.regDate}</td>
                  <td>
                    <input
                      defaultChecked={data.replied === "1"}
                      type="checkbox"
                      name="replied"
                      id={data._id}
                      onChange={onRepliedButtonClickHandler}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <>Loading... </>
      )}
    </>
  );
}

export default ListEstimate;
