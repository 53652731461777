import React from "react";
import styled from "styled-components";
import Select from "Components/Input/Select";
import { categoryObj, productArr } from "util/category/category";

const SelectArea = styled.div`
  display: inline-block;
  width: 350px;
`;

function AdminCategory({
  mainCategory,
  subCategory,
  setMainCategory,
  setSubCategory,
}) {
  const categoryInit = (e) => {
    setMainCategory(e.target.value);
    subCategoryInit(e.target.value);
    setSubCategory("all");
  };

  const subCategoryInit = (main) => {
    const arr = [];
    if (main === "all") return [];

    Object.keys(categoryObj[main].children).forEach((data) => {
      arr.push(data);
    });
    return arr;
  };

  const subCategorySelectHandler = (e) => {
    setSubCategory(e.target.value);
  };

  return (
    <SelectArea>
      <Select onChange={categoryInit} defaultValue={mainCategory}>
        <option value={"all"} onClick={categoryInit}>
          전체보기
        </option>
        {productArr.map((main, idx) => {
          return (
            <option key={idx} value={main} onClick={categoryInit}>
              {categoryObj[main].label}
            </option>
          );
        })}
      </Select>

      <Select onChange={subCategorySelectHandler} defaultValue={subCategory}>
        <option value={"all"}>전체보기</option>
        {subCategoryInit(mainCategory).map((child, idx) => {
          return (
            <option key={idx} value={child}>
              {categoryObj[mainCategory]?.children[child].label}
            </option>
          );
        })}
      </Select>
    </SelectArea>
  );
}

export default AdminCategory;
