async function convertImageToFile(imageUrl, fileName, server) {
  const url = imageUrl.startsWith("blob") ? imageUrl : server + imageUrl;
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], fileName, { type: "image/jpeg" });
  return file;
}

export function handleConvertImage(arr, server) {
  const result = [];
  arr.forEach(async (url) => {
    const imageUrl = url;
    const urlCut = url.split("/");
    const fileName = urlCut[urlCut.length - 1];
    result.push(await convertImageToFile(imageUrl, fileName, server));
  });
  return result;
  // Do something with the file here
}

export const getByteSize = (size) => {
  const byteUnits = ["KB", "MB", "GB", "TB"];

  for (let i = 0; i < byteUnits.length; i++) {
    size = Math.floor(size / 1024);

    if (size < 1024) return size.toFixed(1) + byteUnits[i];
  }
};
