import { Button } from "antd";
import Title from "Components/Title/Title";
import Wrapper from "Components/Wrapper/Wrapper";
import { useFuro } from "furo-react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { checkAdmin } from "_actions/admin_action";
import ListEstimate from "./Estimate/ListEstimate";
import InsertNotice from "./Notice/InsertNotice";
import ListNotice from "./Notice/ListNotice";
import InsertProduct from "./Product/InsertProduct";
import ListProduct from "./Product/ListProduct";
import UpdateProduct from "./Product/UpdateProduct";

function Admin() {
  const { user, logout, getAccessTokenSilently } = useFuro();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const updateData = location.state;

  console.log(updateData);

  const updatePage = updateData ? updateData : {};
  const [admin, setAdmin] = useState();
  const [page, setPage] = useState(updatePage.target);
  async function adminInit() {
    // 로그인한 유저의 데이터를 페이로드로 갖고 내 프로젝트의 Client Secret으로 서명된 JWT를 Furo로부터 받습니다.
    const JWT = await getAccessTokenSilently();

    const header = {
      headers: {
        Authorization: `Bearer ${JWT}`,
      },
    };

    const body = { email: user ? user.email : "loading..." };

    dispatch(checkAdmin({ body: body, header: header })).then((res) => {
      if (!res.payload.isAuth || !res.payload.success) {
        alert(res.payload.message);
        // logout();
        navigate("/");
      }
    });

    setAdmin(body);
  }

  useEffect(() => {
    adminInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      {admin ? (
        <Wrapper>
          <Title style={{ margin: "0 auto" }}>
            관리자 페이지 : {admin.email}
          </Title>
          <LogoutArea>
            <Button onClick={logout}>로그 아웃</Button>
          </LogoutArea>
          <ContentArea>
            <ButtonArea>
              <Button onClick={() => setPage("insert-product")}>
                상품 등록
              </Button>
              <Area />
              <Button onClick={() => setPage("list-product")}>상품 보기</Button>
              {/* <Area />
              <Button onClick={() => setPage("insert-notice")}>
                공지 등록
              </Button>
              <Button onClick={() => setPage("list-product")}>공지 보기</Button> */}
              <Area />
              <Button onClick={() => setPage("list-estimate")}>
                견적 보기
              </Button>
            </ButtonArea>
            <Body>
              {page === "insert-product" ? (
                <InsertProduct />
              ) : page === "list-product" ? (
                <ListProduct />
              ) : page === "insert-notice" ? (
                <InsertNotice />
              ) : page === "list-notice" ? (
                <ListNotice />
              ) : page === "list-estimate" ? (
                <ListEstimate />
              ) : page === "update-product" ? (
                <UpdateProduct data={updateData.product} />
              ) : null}
            </Body>
          </ContentArea>
        </Wrapper>
      ) : (
        <>Loading...</>
      )}
    </>
  );
}

export default Admin;

const LogoutArea = styled.div`
  display: block;
  text-align: center;
  position: sticky;
  top: 0;
`;

const ContentArea = styled.div``;

const ButtonArea = styled.div`
  background-color: #fff;
  padding: 20px 0;
  text-align: center;
  position: sticky;
  top: 0;

  & button {
    width: 100px;
    margin: 0 10px;
  }
`;

const Area = styled.span`
  font-size: 20px;
  &::after {
    content: "|";
  }
`;

const Body = styled.div`
  width: 100%;
`;
