import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const InputText = styled.input`
  width: 30%;
  height: 30px;
  margin: 5px 0;
  border: 1px solid #999;
  border-radius: 4px;
  padding-left: 10px;
`;

function DetailKeyInputText({ setData, data }) {
  const [value, setValue] = useState(data.set);

  useEffect(() => {
    setValue(data.set);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const inputHandler = (e) => {
    setValue(e.target.value);
    setData(e.target);
  };

  return (
    <InputText
      value={value}
      onChange={inputHandler}
      data-idx={data.idx}
      id={"key_" + data.idx}
    />
  );
}

export default DetailKeyInputText;
