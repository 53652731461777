import { ProductContext } from "contexts/ProductContext";
import { useId } from "react";
import { useContext } from "react";
import { useState } from "react";
import styled from "styled-components";

const FileInput = styled.div`
  margin: 15px 0;

  & input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
`;

const Placeholder = styled.input`
  display: inline-block;
  width: 45%;
  height: 40px;
  padding: 0 10px;
  vertical-align: middle;
  border: 1px solid #999;
  border-radius: 4px;
  color: #999999;
`;

const Label = styled.label`
  display: inline-block;
  width: 22%;
  height: 40px;
  line-height: 40px;
  color: #fff;
  vertical-align: middle;
  background-color: #999999;
  cursor: pointer;
  margin-left: 3%;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
`;

const ImagesPreview = styled.button`
  display: inline-block;
  width: 22%;
  height: 40px;
  line-height: 40px;
  color: #fff;
  vertical-align: middle;
  background-color: #8cc7d7;
  cursor: pointer;
  margin-left: 3%;
  border-radius: 4px;
  border: none;
  font-size: 14px;
`;

const PreviewArea = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewBack = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #333;
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Preview = styled.div`
  width: 1200px;
  height: 800px;
  overflow: overlay;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 11;

  & img {
    width: fit-content;
    max-width: 100%;
    display: inline-block;
    cursor: pointer;
  }
  & img:hover {
    filter: brightness(0.7);
  }
`;

const maxSize = 10;

function FileUploader({ images, setImages, setImageFiles }) {
  const { server } = useContext(ProductContext);

  const imgPrevLoop = (images) => {
    const result = [];

    for (let i = 0; i < images.length; i++) {
      const url = images[i].startsWith("blob") ? images[i] : server + images[i];
      result.push(
        <img
          key={"image_" + i}
          src={url}
          alt=""
          onClick={() => {
            deleteAction(i);
          }}
        />
      );
    }
    return result;
  };

  const [showPreview, setShowPreview] = useState(false);
  const [imgPreview, setImgPreview] = useState(imgPrevLoop(images));

  const fileInputHandler = async (e) => {
    const files = e.target.files;
    if (images.length + e.target.files.length > maxSize) {
      alert(`${maxSize}개까지만 등록 가능합니다`);
      return;
    }

    setImageFiles((prev) => {
      const fileList = [...prev, ...files];
      return fileList;
    });

    await setImages((prev) => {
      const obj = prev;
      for (const file of files) {
        obj.push(URL.createObjectURL(file));
      }
      return obj;
    });

    setImgPreview(imgPrevLoop(images));
  };

  const deleteAction = (num) => {
    console.log("delete action");

    setImageFiles((prev) => {
      const result = prev;
      const dataTranster = new DataTransfer();

      Array.from(result)
        .filter((file) => result[num] !== file)
        .forEach((file) => {
          dataTranster.items.add(file);
        });
      console.log(dataTranster);

      fileToImageDelete(dataTranster.files);
      return dataTranster.files;
    });
  };

  const fileToImageDelete = async (files) => {
    await setImages(() => {
      const arr = [];
      for (const file of files) {
        arr.push(URL.createObjectURL(file));
      }

      setImgPreview(imgPrevLoop(arr));
      return arr;
    });
  };

  const id = useId();
  const placeholder = images.length + "개 이미지";

  return (
    <>
      <FileInput>
        <Placeholder placeholder={placeholder} onChange={() => {}} />
        <Label htmlFor={id}>업로드</Label>
        <input type="file" id={id} onChange={fileInputHandler} multiple />
        <ImagesPreview onClick={() => setShowPreview(true)}>
          미리보기
        </ImagesPreview>
      </FileInput>
      {showPreview && (
        <PreviewArea>
          <PreviewBack onClick={() => setShowPreview(false)} />
          <Preview>{imgPreview}</Preview>
        </PreviewArea>
      )}
    </>
  );
}

export default FileUploader;
