import React from "react";
import styled from "styled-components";
import Select from "Components/Input/Select";
import { categoryObj, productArr } from "util/category/category";

const SelectArea = styled.div`
  /* width: 350px; */
`;

function Category({
  mainCategory,
  subCategory,
  setMainCategory,
  setSubCategory,
}) {
  const categoryInit = (e) => {
    setMainCategory(e.target.value);
    subCategoryInit(e.target.value);
    setSubCategory(Object.keys(categoryObj[e.target.value].children)[0]);
  };

  const subCategoryInit = (main) => {
    const arr = [];
    if (mainCategory === "") return [];

    Object.keys(categoryObj[main].children).forEach((data) => {
      arr.push(data);
    });
    return arr;
  };

  const subCategorySelectHandler = (e) => {
    setSubCategory(e.target.value);
  };

  return (
    <SelectArea>
      <Select onChange={categoryInit} defaultValue={mainCategory}>
        {productArr.map((main, idx) => {
          return (
            <option key={idx} value={main} onClick={categoryInit}>
              {categoryObj[main].label}
            </option>
          );
        })}
      </Select>

      <Select onChange={subCategorySelectHandler} defaultValue={subCategory}>
        {subCategoryInit(mainCategory).map((child, idx) => {
          return (
            <option key={idx} value={child}>
              {categoryObj[mainCategory]?.children[child].label}
            </option>
          );
        })}
      </Select>
    </SelectArea>
  );
}

export default Category;
