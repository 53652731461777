import Button from "Components/Button/Button";
import React from "react";
import { Link } from "react-router-dom";
import { media } from "style/media";
import styled from "styled-components";

function SubMenu({ subMenu }) {
  const subMenuObj = subMenu.submenu;

  const smCnt =
    Object.keys(subMenuObj).length >= 3 ? 3 : Object.keys(subMenuObj).length;
  const mdCnt =
    Object.keys(subMenuObj).length > 4 ? 4 : Object.keys(subMenuObj).length;

  return (
    <SubMenuarea
      cnt={Object.keys(subMenuObj).length}
      mdCnt={
        Object.keys(subMenuObj).length > 4 ? 4 : Object.keys(subMenuObj).length
      }
      smCnt={
        Object.keys(subMenuObj).length >= 3 ? 3 : Object.keys(subMenuObj).length
      }
    >
      {Object.keys(subMenuObj).map((menu, idx) => {
        return (
          <Link
            to={subMenu.location.pathname}
            state={subMenuObj[menu].key}
            key={idx}
          >
            <Button clicked={subMenu.now === subMenuObj[menu].key}>
              <SubMenuText>{subMenuObj[menu].label}</SubMenuText>
            </Button>
          </Link>
        );
      })}
    </SubMenuarea>
  );
}

export default SubMenu;

const SubMenuarea = styled.div`
  width: 100%;
  background-color: #fff;
  display: grid;
  justify-content: center;
  position: sticky;
  top: 0;

  z-index: 8;
  padding: 20px 0;

  font-size: 14px;
  grid-template-columns: repeat(${(props) => props.cnt}, 140px);
  gap: 10px;

  ${media.middle`
		font-size: 13px;
		grid-template-columns: repeat(${(props) => props.mdCnt}, 125px);
		gap: 8px;
		`}

  ${media.small`
		font-size: 12px;
		display: grid;
		grid-template-columns: repeat(${(props) => props.smCnt}, 90px);
		gap: 5px;
	`}
`;

const SubMenuText = styled.span`
  width: 100%;
  height: 40%;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: keep-all;

  font-weight: 600;
  line-height: 100%;
`;
