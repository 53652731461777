export const introduce = {
  label: "회사소개",
  key: "introduce",
  banner: "banner1.jpg",
};
export const productArr = ["pc", "display", "live", "pack", "mobile"];
export const pc = {
  label: "PC",
  key: "pc",
  default: "desktop",
  children: {
    desktop: { label: "데스크탑", key: "desktop" },
    notebook: { label: "노트북", key: "notebook" },
    one: { label: "일체형PC", key: "one" },
    pcacc: { label: "악세사리", key: "pcacc" },
  },
  banner: "banner2.jpg",
};
export const display = {
  label: "디스플레이",
  key: "display",
  default: "ledM",
  children: {
    ledM: { label: "LED모니터", key: "ledM" },
    // curvedM: { label: "커브드모니터", key: "curvedM" },
    businessT: { label: "비즈니스TV", key: "businessT" },
    // ledT: { label: "LEDTV", key: "ledT" },
    extraT: { label: "초대형TV", key: "extraT" },
    displayacc: { label: "악세사리", key: "displayacc" },
  },
  banner: "banner1.jpg",
};
export const live = {
  label: "가전",
  key: "live",
  default: "air",
  children: {
    air: { label: "공기청정기", key: "air" },
    vacuum: { label: "청소기", key: "vacuum" },
    refrig: { label: "냉장고", key: "refrig" },
    microwave: { label: "전자레인지/전기레인지", key: "microwave" },
    oven: { label: "오븐/쿠커", key: "oven" },
    dish: { label: "식기세척기", key: "dish" },
    airconditioner: { label: "냉난방기", key: "airconditioner" },
  },
  banner: "banner3.jpg",
};
export const pack = {
  label: "패키지",
  key: "pack",
  default: "meet",
  children: {
    meet: { label: "회의실", key: "meet" },
    office: { label: "사무실", key: "office" },
    study: { label: "스터디룸", key: "study" },
    accom: { label: "숙박업소", key: "accom" },
    cafe: { label: "카페", key: "cafe" },
  },
  banner: "banner2.jpg",
};
export const mobile = {
  label: "모바일",
  key: "mobile",
  default: "phone",
  children: {
    phone: { label: "자급제폰", key: "phone" },
    tablet: { label: "태블릿", key: "tablet" },
    wear: { label: "웨어러블", key: "wear" },
    mobileacc: { label: "악세사리", key: "mobileacc" },
  },
  banner: "banner1.jpg",
};
export const estimate = {
  label: "견적문의",
  key: "estimate",
  banner: "banner2.jpg",
};
export const cs = {
  label: "고객센터",
  key: "cs",
  default: "notice",
  children: {
    notice: { label: "공지사항", key: "notice" },
    qna: { label: "1:1문의", key: "qna" },
  },
  banner: "banner1.jpg",
};

export const categoryObj = {
  introduce: introduce,
  pc: pc,
  display: display,
  live: live,
  mobile: mobile,
  pack: pack,
  estimate: estimate,
  cs: cs,
};

export const getCategory = () => {
  const Arr = [];
  Object.keys(categoryObj).forEach((key) => {
    Arr.push(categoryObj[key]);
  });
  return Arr;
};

export const getBannerName = (pathname, custom) => {
  const path = pathname.replace("/", "");

  if (custom) {
    return custom.img;
  }

  const image = categoryObj[path]?.banner;

  if (image === undefined) return "banner1.jpg";

  return categoryObj[path].banner;
};

export const getCategoryQuery = (main, sub) => {
  if (main === "all") {
    return { $ne: { main: null } };
  }
  if (main !== "all" && sub === "all") {
    const queryInArray = [];
    const children = categoryObj[main].children;
    Object.keys(children).forEach((child) => {
      queryInArray.push({ main: main, sub: children[child].key });
    });
    return { $in: queryInArray };
  }
  if (main !== "all" && sub !== "all") {
    return { $eq: { main: main, sub: sub } };
  }
  return {};
};
