import axios from "axios";
import { GET_PROVIDER } from "./types";

export function getInit(dataToSubmit) {
  const request = axios
    .post("/api/provider/init", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_PROVIDER,
    payload: request,
  };
}
