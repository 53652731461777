import axios from "axios";
import { IS_ADMIN } from "./types";

export function checkAdmin(dataToSubmit) {
  const request = axios
    .post("/api/admin/check", dataToSubmit.body, dataToSubmit.header)
    .then((response) => {
      return response.data;
    });

  return {
    type: IS_ADMIN,
    payload: request,
  };
}
