import { media } from "style/media";
import styled from "styled-components";

const CustomButton = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 17.5px;
  color: ${(props) => (props.clicked === true ? "#fff" : "#333")};
  background-color: ${(props) =>
    props.clicked === true ? "rgba(24,144,255)" : "#eee"};

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 3px #00000012;

  ${media.small`
		width: 90px;
	`}
`;

function Button({ children, onClick, clicked }) {
  return (
    <CustomButton onClick={onClick} clicked={clicked}>
      {children}
    </CustomButton>
  );
}

export default Button;
