import { Breadcrumb } from "antd";
import { useFuro } from "furo-react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { media } from "style/media";
import styled from "styled-components";
import { getNotices, deleteNotice } from "_actions/notice_action";
import Detail from "./Detail";
import Write from "./Write";

const Body = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: none;
  font-size: 14px;
  width: 100%;
  margin: 0 0 100px 0;
  word-break: keep-all;

  & tr {
    border-bottom: 1px solid #999;
    cursor: pointer;
  }

  & tr:hover {
    background-color: #dee8ef69;
  }

  & tr th {
    background-color: #eee;
    height: 40px;

    ${media.small`
			height: 20px;
		`}
  }

  & tr td {
    height: 40px;
    font-size: 14px;

    ${media.small`
			height: 30px;
			font-size: 12px;
		`}
  }

  & tr td:nth-child(2) {
    padding: 0 30px;
  }

  & tr th:first-child {
    width: 10%;
  }

  & tr th:nth-child(2) {
    width: 65%;
  }

  & tr th:nth-child(3) {
    /* width: 25%; */
  }

  ${media.small`
		font-size: 10px;
  `}
`;

const WriteButton = styled.button`
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  vertical-align: middle;
  background-color: #8cc7d7;
  cursor: pointer;
  margin-left: 0;
  margin-bottom: 20px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
`;

const Delete = styled.button`
  width: 60px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
    border: 1px solid #333;
  }
`;

function Notice() {
  const dispatch = useDispatch();
  const location = useLocation();

  const id = location.search.replace("?", "");

  const { user } = useFuro();
  const [isWrite, setIsWrite] = useState(false);
  const [list, setList] = useState([]);

  const [showDetail, setShowDetail] = useState();

  const getList = () => {
    dispatch(getNotices()).then((res) => {
      if (res.payload.success) {
        setList(res.payload.data);
        if (id) {
          const data = res.payload.data.filter((data) => data._id === id)[0];

          setShowDetail(
            <Detail
              data={data}
              setShowDetail={setShowDetail}
              getList={getList}
            />
          );
        }
      }
    });
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWriteButtonClickHandler = () => {
    setIsWrite(true);
  };

  const checkPassword = (data) => {
    if (user) return true;
    const inputPassword = prompt("비밀번호를 입력하세요.");
    if (data.password === inputPassword) return true;
    return false;
  };

  const onRowClickHandler = (data) => {
    if (data.isPrivate === "1") {
      if (!checkPassword(data)) {
        alert("비밀번호가 틀렸습니다.");
        return;
      }
    }

    setShowDetail(
      <Detail data={data} setShowDetail={setShowDetail} getList={getList} />
    );
  };

  const onDeleteButtonClickHandler = (e) => {
    console.log(e.target.dataset.id);
    const question = `삭제 하시겠습니까?`;
    const checker = window.confirm(question);

    if (!checker) return;

    const body = { _id: e.target.dataset.id };

    dispatch(deleteNotice(body)).then((res) => {
      alert("삭제 되었습니다");

      getList();
    });
  };

  return (
    <>
      <Breadcrumb
        items={[{ title: "홈" }, { title: "고객센터" }, { title: "공지사항" }]}
      />
      <Body>
        {isWrite && <Write setIsWrite={setIsWrite} getList={getList} />}
        {user && (
          <WriteButton onClick={onWriteButtonClickHandler}>글쓰기</WriteButton>
        )}
        <Table>
          <tbody>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>등록일</th>
              {user && <th>삭제</th>}
            </tr>
            {list.map((data, idx) => (
              <tr key={data._id}>
                <td onClick={() => onRowClickHandler(data)} align="center">
                  {idx + 1}
                </td>
                <td onClick={() => onRowClickHandler(data)} align="center">
                  {data.title}
                </td>
                <td onClick={() => onRowClickHandler(data)} align="center">
                  {data.regDate.split(" ")[0]}
                </td>
                {user && (
                  <td align="center">
                    <Delete
                      onClick={onDeleteButtonClickHandler}
                      data-id={data._id}
                    >
                      삭제
                    </Delete>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Body>
      {showDetail}
    </>
  );
}

export default Notice;
