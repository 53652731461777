import { Breadcrumb } from "antd";
import TextBar from "Components/Input/TextBar";
import TextArea from "Components/Input/TextArea";
import Wrapper from "Components/Wrapper/Wrapper";
import { useState } from "react";
import { media } from "style/media";
import styled from "styled-components";
import { PrivacyText } from "./privacy";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { insertEstimate } from "_actions/estimate_action";

const TitleBR = styled.div`
  width: 100%;
  height: 13px;
  border-bottom: 2px solid #333;
`;

const Body = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const Required = styled.span`
  color: red;
  margin: 0 10px;
  &:after {
    content: "*";
  }
`;

const Text = styled.span`
  color: #333;
  font-size: 16px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: none;
  font-size: 16px;
  width: 100%;
  margin: 0 0 50px 0;
  word-break: keep-all;

  & tr {
    border-bottom: 1px solid #999;
  }

  & tr th {
    width: 4%;
    height: 70px;
  }

  & tr td:last-child {
    width: 75%;
    height: 70px;
  }

  ${media.small`
		font-size: 12px;
  `}
`;

const PrivacyArea = styled.div`
  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const PrivacyBox = styled.div`
  width: 100%;
  border: 1px solid #999;
  border-radius: 4px;
  height: 200px;
  margin: 5px 0;
  padding: 10px;
  overflow: auto;
  font-size: 12px;
`;
const Agree = styled.span`
  display: block;
  font-size: 14px;
  float: right;
`;

const Confirm = styled.button`
  display: block;
  width: 120px;
  height: 40px;
  background-color: #000;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  margin: 40px auto;
`;

function Estimate() {
  const location = useLocation();
  const title = location.state?.product;
  console.log(location.state);

  const [data, setData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    text: title ? `${title} 상품 문의합니다.` : "",
    agree: "true",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [agree, setAgree] = useState();

  const onConfiemButtonHandler = () => {
    if (
      data.name === "" ||
      data.phone === "" ||
      data.text === "" ||
      data.agree === "" ||
      data.company === ""
    ) {
      alert("필수 입력란을 작성 해주세요.");
      return;
    }
    if (agree !== "true") {
      alert("개인정보 취급방침에 동의 해주세요.");
      return;
    }

    dispatch(insertEstimate(data)).then((res) => {
      alert(res.payload.message);
      if (res.payload.success) {
        navigate(0);
      }
    });
  };

  return (
    <>
      <Wrapper>
        <Breadcrumb items={[{ title: "홈" }, { title: "견적문의" }]} />
        <Body>
          <Text>
            <Required />
            필수 입력사항 입니다.
          </Text>
          <TitleBR />
        </Body>
        <Table>
          <tbody>
            <tr>
              <th>
                <Required />
              </th>
              <td>신청자명</td>
              <td>
                <TextBar
                  valName={"name"}
                  setData={setData}
                  data={data}
                  placeholder={"신청자명을 입력 해주세요"}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Required />
              </th>
              <td>사업자명</td>
              <td>
                <TextBar
                  valName={"company"}
                  setData={setData}
                  data={data}
                  placeholder={"사업자명을 입력 해주세요"}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Required />
              </th>
              <td>휴대폰번호</td>
              <td>
                <TextBar
                  valName={"phone"}
                  setData={setData}
                  data={data}
                  placeholder={"010-1234-5678"}
                />
              </td>
            </tr>
            <tr>
              <th></th>
              <td>이메일주소</td>
              <td>
                <TextBar
                  valName={"email"}
                  setData={setData}
                  data={data}
                  type={"email"}
                  placeholder={"user@kmobileb2b.com"}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Required />
              </th>
              <td>문의 및 요청사항</td>
              <td>
                <TextArea
                  defaultValue={data.text}
                  valName={"text"}
                  setData={setData}
                  data={data}
                  placeholder={"내용을 입력해주세요"}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Text>
          <Required />
          개인정보 취급방침 동의
        </Text>
        <PrivacyArea>
          <PrivacyBox>{PrivacyText}</PrivacyBox>
          <Agree>
            위의 "개인정보 취급방침"에
            <input
              value={true}
              type="radio"
              name="agree"
              onChange={(e) => setAgree(e.target.value)}
              id={"agree"}
            />
            <label htmlFor="agree">동의합니다.</label>
            <input
              value={false}
              type="radio"
              name="agree"
              onChange={(e) => setAgree(e.target.value)}
              id={"disagree"}
            />
            <label htmlFor="disagree">동의하지 않습니다.</label>
          </Agree>
        </PrivacyArea>
        <Confirm onClick={onConfiemButtonHandler}>확인</Confirm>
      </Wrapper>
    </>
  );
}

export default Estimate;
