import React, { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getInit } from "_actions/provider_action";

export const ProductContext = createContext(null);

export const ProductProvider = ({ children }) => {
  const [productionInit, setProductionInit] = useState();
  const [load, setLoad] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInit()).then((result) => {
      setProductionInit(result.payload);
      setLoad(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {load && (
        <ProductContext.Provider value={productionInit}>
          <>{children}</>
        </ProductContext.Provider>
      )}
    </>
  );
};
