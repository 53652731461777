import axios from "axios";
import {
  GET_ESTIMATE,
  GET_ESTIMATES,
  WRITE_ESTIMATE,
  DELETE_ESTIMATE,
  UPDATE_ESTIMATE,
  REPLIED_ESTIMATE,
} from "./types";

export function getEstimate(dataToSubmit) {
  const request = axios
    .post("/api/estimate/get", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_ESTIMATE,
    payload: request,
  };
}

export function getEstimates(dataToSubmit) {
  const request = axios
    .post("/api/estimate/list", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_ESTIMATES,
    payload: request,
  };
}

export function insertEstimate(dataToSubmit) {
  const request = axios
    .post("/api/estimate/insert", dataToSubmit)
    .then((response) => response.data);

  return {
    type: WRITE_ESTIMATE,
    payload: request,
  };
}

export function deleteEstimate(dataToSubmit) {
  const request = axios
    .post("/api/estimate/delete", dataToSubmit)
    .then((response) => response.data);

  return {
    type: DELETE_ESTIMATE,
    payload: request,
  };
}

export function updateEstimate(dataToSubmit) {
  const request = axios
    .post("/api/estimate/update", dataToSubmit)
    .then((response) => response.data);

  return {
    type: UPDATE_ESTIMATE,
    payload: request,
  };
}

export function repliedEstimate(dataToSubmit) {
  const request = axios
    .post("/api/estimate/replied", dataToSubmit)
    .then((response) => response.data);

  return {
    type: REPLIED_ESTIMATE,
    payload: request,
  };
}
