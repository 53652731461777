export const GET_PROVIDER = "get_provider";

export const GET_PRODUCT = "get_product";
export const GET_PRODUCTS = "get_product";
export const WRITE_PRODUCT = "write_product";
export const DELETE_PRODUCT = "delete_product";
export const UPDATE_PRODUCT = "update_product";
export const BEST_PRODUCT = "best_product";

export const GET_ESTIMATE = "get_estimate";
export const GET_ESTIMATES = "get_estimates";
export const WRITE_ESTIMATE = "write_estimates";
export const DELETE_ESTIMATE = "delete_estimates";
export const UPDATE_ESTIMATE = "update_estimates";
export const REPLIED_ESTIMATE = "replied_estimates";

export const GET_QNA = "get_qna";
export const GET_QNAS = "get_qnas";
export const WRITE_QNA = "write_qnas";
export const REPLY_QNA = "reply_qnas";
export const DELETE_QNA = "delete_qnas";
export const UPDATE_QNA = "update_qnas";

export const GET_NOTICE = "get_notice";
export const GET_NOTICES = "get_notices";
export const WRITE_NOTICE = "write_notices";
export const REPLY_NOTICE = "reply_notices";
export const DELETE_NOTICE = "delete_notices";
export const UPDATE_NOTICE = "update_notices";

export const AUTH_USER = "auth_user";
export const IS_ADMIN = "is_admin";
export const KEY = "key";
