import axios from "axios";
import {
  GET_NOTICE,
  GET_NOTICES,
  WRITE_NOTICE,
  REPLY_NOTICE,
  DELETE_NOTICE,
  UPDATE_NOTICE,
} from "./types";

export function getNotice(dataToSubmit) {
  const request = axios
    .post("/api/notice/get", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_NOTICE,
    payload: request,
  };
}

export function getNotices(dataToSubmit) {
  const request = axios
    .post("/api/notice/list", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_NOTICES,
    payload: request,
  };
}

export function insertNotice(dataToSubmit) {
  const request = axios
    .post("/api/notice/insert", dataToSubmit)
    .then((response) => response.data);

  return {
    type: WRITE_NOTICE,
    payload: request,
  };
}

export function replyNotice(dataToSubmit) {
  const request = axios
    .post("/api/notice/reply", dataToSubmit)
    .then((response) => response.data);

  return {
    type: REPLY_NOTICE,
    payload: request,
  };
}

export function deleteNotice(dataToSubmit) {
  const request = axios
    .post("/api/notice/delete", dataToSubmit)
    .then((response) => response.data);

  return {
    type: DELETE_NOTICE,
    payload: request,
  };
}

export function updateNotice(dataToSubmit) {
  const request = axios
    .post("/api/notice/update", dataToSubmit)
    .then((response) => response.data);

  return {
    type: UPDATE_NOTICE,
    payload: request,
  };
}
