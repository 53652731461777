import axios from "axios";
import {
  GET_QNA,
  GET_QNAS,
  WRITE_QNA,
  REPLY_QNA,
  DELETE_QNA,
  UPDATE_QNA,
} from "./types";

export function getQna(dataToSubmit) {
  const request = axios
    .post("/api/qna/get", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_QNA,
    payload: request,
  };
}

export function getQnas(dataToSubmit) {
  const request = axios
    .post("/api/qna/list", dataToSubmit)
    .then((response) => response.data);

  return {
    type: GET_QNAS,
    payload: request,
  };
}

export function insertQna(dataToSubmit) {
  const request = axios
    .post("/api/qna/insert", dataToSubmit)
    .then((response) => response.data);

  return {
    type: WRITE_QNA,
    payload: request,
  };
}

export function replyQna(dataToSubmit) {
  const request = axios
    .post("/api/qna/reply", dataToSubmit)
    .then((response) => response.data);

  return {
    type: REPLY_QNA,
    payload: request,
  };
}

export function deleteQna(dataToSubmit) {
  const request = axios
    .post("/api/qna/delete", dataToSubmit)
    .then((response) => response.data);

  return {
    type: DELETE_QNA,
    payload: request,
  };
}

export function updateQna(dataToSubmit) {
  const request = axios
    .post("/api/qna/update", dataToSubmit)
    .then((response) => response.data);

  return {
    type: UPDATE_QNA,
    payload: request,
  };
}
