import { useState } from "react";
import styled from "styled-components";

const InputText = styled.input`
  width: 95%;
  height: 40px;
  margin: 15px 0;
  border: 1px solid #999;
  border-radius: 4px;
  padding-left: 10px;
`;

function AddComma(num) {
  num = num.replaceAll(",", "");
  var regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ",");
}

function FormTextBar({ placeholder, setData, type, data }) {
  const [value, setValue] = useState(data);

  const inputHandler = (event) => {
    const value =
      type === "number" ? AddComma(event.target.value) : event.target.value;
    setValue(value);
    setData(value);
  };

  return (
    <InputText
      value={value}
      placeholder={placeholder}
      onChange={inputHandler}
    />
  );
}

export default FormTextBar;
