import { media } from "style/media";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 200px;
  background-color: #162e4430;
  font-size: 14px;
  ${media.middle`
		font-size: 12px;
  `}
  ${media.small`
		height: 240px;
  `}
`;

const ContentsArea = styled.div`
  width: 80%;
  height: 150px;
  display: flex;
  margin: auto;
  align-items: center;
  ${media.middle`
		width: 90%
  `}

  ${media.small`
		width: 100%;
		flex-direction: column;
		align-items:center;
		justify-content: center;
		height: 200px;
	`}
`;

const TextArea = styled.div`
  width: 70%;
  ${media.small`
		width: 80%;
	`}
`;

const BankArea = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.small`
		width: 80%;
		justify-content: flex-start;
	`}
`;

const Text = styled.p`
  color: #1e2e3c;

  font-weight: 500;
  margin: 8px 0;
  word-break: keep-all;
`;
const BR = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #1e2e3c96;
`;

const Bank = styled.div`
  width: 400px;
  height: 120px;
  background-color: #fff;
  border: 1px solid #1e2e3c61;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.middle`
		font-size: 12px;
		width: 320px;
		height: 80px;
	`}

  ${media.small`
		width: 70%;
		height: 60px;
		
		font-size: 12px;
	`}
`;

const BankInfo = styled.p`
  color: #1e2e3c;
  width: 90%;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0 auto;

  & img {
    width: 40px;

    ${media.middle`
			width: 30px;
		`}
  }

  ${media.small`
		height: 50px;
	`}
`;

const BankInfo2 = styled.p`
  color: #1e2e3c;
  width: 90%;
  height: 30px;
  display: flex;
  align-items: center;
  margin: 0 auto;

  & span {
    margin-left: 40px;
    ${media.middle`
			margin-left: 30px;
		`}
  }

  ${media.small`
		font-size: 12px;
		height: 40px;
  `}
`;

const CopyRight = styled.p`
  color: #1e2e3c82;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;

  ${media.middle`
		
  `}
  ${media.small`
		font-size: 12px;
		line-height: 12px;
		margin:0;
		height: 40px;
  `}
`;

function Footer() {
  return (
    <Wrapper>
      <ContentsArea>
        <TextArea>
          <Text>(주)케이모바일</Text>
          <Text>
            대표:한미옥&nbsp;&nbsp;&nbsp;주소:서울특별시 용산구 원효로 163 4층
          </Text>
          <Text>사업자등록번호:106-86-89103</Text>
          <Text>
            전화:010-2594-8050&nbsp;&nbsp;&nbsp;이메일:ktkmobile1@naver.com
          </Text>
        </TextArea>
        <BankArea>
          <Bank>
            <BankInfo>
              <img src={process.env.PUBLIC_URL + "/images/KB.png"} alt="KB" />
              <span>기업은행 : 284-066317-04-017</span>
            </BankInfo>
            <BankInfo2>
              <span>예금주 : (주) 케이모바일</span>
            </BankInfo2>
          </Bank>
        </BankArea>
      </ContentsArea>
      <BR />
      <CopyRight>
        Copyright&copy; KMOBILEB2B 2023. ALL Right Reaserved. CREATED BY WTWEB.
      </CopyRight>
    </Wrapper>
  );
}

export default Footer;
