import { Route, Routes } from "react-router-dom";
import { useTitle } from "hooks/useTitle";
import Header from "Components/Header/Header";
import Banner from "Components/Banner/Banner";
import styled from "styled-components";
import Main from "Screen/Main/Main";
import Introduce from "Screen/Introduce/Introduce";
import Estimate from "Screen/Estimate/Estimate";
import Detail from "Screen/Detail/Detail";
import CS from "Screen/CS/CS";
import Product from "Screen/Product/Product";
import { productArr } from "util/category/category";
import { RequireAuth } from "util/login/auth";
import Admin from "Screen/Admin/Admin";
import Footer from "Components/Footer/Footer";

const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const HeaderArea = styled.div`
  width: 100%;
`;

const Body = styled.div`
  width: 100%;
  min-height: 70vh;
`;

function Display() {
  useTitle("케이모바일");

  return (
    <Container>
      <HeaderArea>
        <Header />
        <Banner />
      </HeaderArea>
      <Body>
        <Routes>
          <Route
            path="/private"
            element={
              <RequireAuth>
                <Admin />
              </RequireAuth>
            }
          />
          <Route exact path="/" element={<Main />} />
          {productArr.map((key, idx) => {
            return (
              <Route exact key={idx} path={`/${key}`} element={<Product />} />
            );
          })}
          <Route exact path="/introduce" element={<Introduce />} />
          <Route exact path="/estimate" element={<Estimate />} />
          <Route exact path="/cs" element={<CS />} />
          <Route exact path="/detail" element={<Detail />} />
        </Routes>
      </Body>
      <Footer />
    </Container>
  );
}
export default Display;
