import { useState } from "react";
import styled from "styled-components";

const Text = styled.textarea`
  width: 95%;
  border: 1px solid #999;
  border-radius: 4px;
  height: 300px;
  margin: 20px 0;
  padding: 10px;
`;
function TextArea({ placeholder, valName, setData, defaultValue }) {
  const [value, setValue] = useState(defaultValue ? defaultValue : "");

  const inputHandler = (event) => {
    setData((prev) => {
      let data = prev;
      data[valName] = event.target.value;
      setValue(event.target.value);
      return data;
    });
  };

  return (
    <Text
      value={value}
      placeholder={placeholder}
      onChange={inputHandler}
    ></Text>
  );
}

export default TextArea;
