import { useEffect } from "react";
import { useFuro } from "furo-react";
import { useContext } from "react";
import { ProductContext } from "contexts/ProductContext";

export const RequireAuth = ({ children }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useFuro();

  const { isProduct } = useContext(ProductContext);
  console.log("test");
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (!isProduct) return children;

  if (isLoading) {
    return <>Loading...</>;
  }

  return isAuthenticated ? children : <></>;
};

export const key =
  process.env.NODE_ENV === "production"
    ? "16d100c5d94e1096897e0d1ea39f9e9a"
    : "307c0e4ecb35dcac5405a8ba5ebb3d07";
