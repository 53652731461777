import { Breadcrumb, Button, ConfigProvider } from "antd";
import DetailCarousel from "Components/Carousel/DetailCarousel";
import { useFuro } from "furo-react";
import { Link } from "react-router-dom";
import { media } from "style/media";
import styled from "styled-components";
import { categoryObj } from "util/category/category";

const SummaryArea = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  ${media.middle`
			flex-direction: column;
	`}
  ${media.small`
			flex-direction: column;
	`}
`;

const SummaryL = styled.div`
  width: 540px;

  ${media.middle`
			width: 100%;
			flex-direction: column;
		`}
  ${media.small`
		width: 100%;
		flex-direction: column;
		`}
`;

const SummaryR = styled.div`
  width: 750px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.middle`
		width: 100%;
		flex-direction: column;
		padding-left: 0;
	`}
  ${media.small`
		width: 100%;
		flex-direction: column;
		padding-left: 0;
	`}
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: 500;
  display: block;
  margin: 20px 0;
`;

const SubTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  display: block;
  ${media.middle`
		margin-top: 30px;
	`}
  ${media.small`
		margin-top: 30px;
	`}
`;

const BR = styled.div`
  width: 100%;
  height: 13px;
  border-bottom: 1px solid #999;
`;

const SummaryTextarea = styled.div`
  padding-top: 28px;
`;

const SummaryTextLine = styled.p`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SummaryTextKey = styled.span`
  display: inline-block;
  width: 40%;
  font-size: 16px;
  color: #666;
`;

const SummaryTextValue = styled.span`
  display: inline-block;
  width: 60%;
  font-size: 16px;
  font-weight: 500;
  color: #333;
`;

const SummaryTextTopArea = styled.div`
  width: 100%;
`;

const SummaryTextBottomArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

function DetailHead({ data }) {
  const theme = {
    token: {
      colorPrimary: "#1890ff",
      colorPrimaryBg: "#cbe6ff",
    },
  };

  console.log(data);
  const { user } = useFuro();
  return (
    <>
      <Breadcrumb
        items={[
          { title: "홈" },
          { title: categoryObj[data.category.main].label },
          {
            title:
              categoryObj[data.category.main].children[data.category.sub].label,
          },
        ]}
      />
      <Title>{data.title}</Title>
      <SummaryArea>
        <SummaryL>
          <DetailCarousel images={data.mainImages} />
        </SummaryL>
        <SummaryR>
          <SummaryTextTopArea>
            <SubTitle>
              상세설명
              {user && (
                <Button
                  style={{
                    margin: "0 auto",
                    float: "right",
                  }}
                >
                  <Link
                    to="/private"
                    state={{ product: data, target: "update-product" }}
                  >
                    수정
                  </Link>
                </Button>
              )}
            </SubTitle>

            <BR />
            <SummaryTextarea>
              {Object.keys(data.detail).map((obj) => {
                if (!data.detail[obj].key) return <></>;
                return (
                  <SummaryTextLine key={data.detail[obj.idx]}>
                    <SummaryTextKey>{data.detail[obj].key}</SummaryTextKey>
                    <SummaryTextValue>
                      {data.detail[obj].value}
                    </SummaryTextValue>
                  </SummaryTextLine>
                );
              })}
            </SummaryTextarea>
          </SummaryTextTopArea>
          <SummaryTextBottomArea>
            <BR />
            <ConfigProvider theme={theme}>
              <Link to="/estimate" state={{ product: data.title }}>
                <Button
                  type="primary"
                  style={{
                    margin: "10px auto 20px",
                    width: "100%",
                    height: "40px",
                  }}
                >
                  견적문의
                </Button>
              </Link>
            </ConfigProvider>
          </SummaryTextBottomArea>
        </SummaryR>
      </SummaryArea>
    </>
  );
}

export default DetailHead;
