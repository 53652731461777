import useBlockScroll from "hooks/useBlockScroll";
import styled from "styled-components";

const LoadingBackground = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 999;
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: #00000099;
`;

const Loading = ({ loading }) => {
  useBlockScroll();
  const check = loading === true;

  return (
    check && (
      <LoadingBackground>
        <span>Loading... </span>
      </LoadingBackground>
    )
  );
};

export default Loading;
