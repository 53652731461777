import { useEffect } from "react";

function useBlockScroll() {
  useEffect(() => {
    const handleScroll = (e) => {
      e.preventDefault();
    };

    // Add an event listener to the document object
    document.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
}

export default useBlockScroll;
