import { ConfigProvider } from "antd";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { media } from "style/media";
import styled from "styled-components";
import DetailBody from "./DetailBody";
import DetailHead from "./DetailHead";

const DetailWrapper = styled.div`
  margin: 60px auto 0;
  width: 150rem;
  max-width: 1200px;
  ${media.middle`
			width: 90%;
		`}
  ${media.small`
			width: 90%;
		`}
`;

function Detail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [init, setInit] = useState(false);

  const myRef = useRef(null);
  const data = location.state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!data) {
      alert("비 정상적인 접근입니다.");
      navigate("/");
    } else {
      setInit(true);
    }
  }, [data, navigate]);

  const theme = {
    token: {
      colorPrimary: "#1890ff",
      colorPrimaryBg: "#cbe6ff",
    },
  };

  return (
    <>
      {init === true ? (
        <DetailWrapper ref={myRef}>
          <ConfigProvider theme={theme}>
            <DetailHead data={data} />
            <DetailBody data={data} />
          </ConfigProvider>
        </DetailWrapper>
      ) : (
        <>Loading... </>
      )}
    </>
  );
}

export default Detail;
