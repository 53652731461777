import { Link, useLocation } from "react-router-dom";
import { media } from "style/media";
import styled from "styled-components";

const Side = styled.div`
  width: 25em;
  min-width: 200px;
  text-align: center;
  position: fixed;

  top: 15%;
  right: 3%;

  & img {
    width: 100%;
    opacity: 0.85;
    transition: all 100ms;
    z-index: 99;
  }

  & img:hover {
    opacity: 1;
  }

  ${media.middle`
		& img {
			opacity: 0.95;
			transition: all 100ms;
		}
		width: 23em;
		min-width: 120px;
		top: unset;
		top: 10%;
  `}

  ${media.small`
		& img {
			opacity: 0.8;
			transition: all 100ms;
		}
		min-width: 100px;
		top: 10%;
		right: 12%;

		overflow:hidden;
		& a:not(:first-child) img{
			transform:scale(1.4)
		}
		& a:first-child img{
			/* transform:scale(1.4) */
			opacity:1;
		}
	`}
`;

const onKakaoLinkButtonClickHandler = () => {
  window.open("http://pf.kakao.com/_JMxcYxj/chat");
};

const SideBanner = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/detail" && location.pathname !== "/private" && (
        <Side>
          <Link to={"/cs"} state={"qna"}>
            <img
              src={process.env.PUBLIC_URL + "/images/side/1.jpg"}
              alt="상담문의"
            />
          </Link>
          <Link onClick={onKakaoLinkButtonClickHandler}>
            <img
              src={process.env.PUBLIC_URL + "/images/side/2.jpg"}
              alt="상담문의"
            />
          </Link>
          <Link to={"/estimate"}>
            <img
              src={process.env.PUBLIC_URL + "/images/side/3.jpg"}
              alt="상담문의"
            />
          </Link>
          <Link>
            <img
              src={process.env.PUBLIC_URL + "/images/side/4.jpg"}
              alt="상담문의"
            />
          </Link>
        </Side>
      )}
    </>
  );
};

export default SideBanner;
