import { media } from "style/media";

const { default: styled } = require("styled-components");

const TitleText = styled.span`
  font-size: 3.8rem;
  font-weight: 500;
  margin: 10rem 0 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-top: 3rem;
  ${media.middle`
		font-size: 5rem;
	`}
`;

const TitleUnderLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 3rem;

  box-shadow: 0 4px 3px #00000012;
`;

const Title = ({ children }) => {
  return (
    <>
      <TitleText>
        {children}
        <TitleUnderLine />
      </TitleText>
    </>
  );
};

export default Title;
