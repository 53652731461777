import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FileUploader from "Components/Input/FileUploader";
import FormTextBar from "Components/Input/FormTextBar";
import Title from "Components/Title/Title";

import { media } from "style/media";
import styled from "styled-components";

import Category from "./Form/Category";
import Detail from "./Form/Detail";

import imageCompression from "browser-image-compression";
import { insertProduct } from "_actions/product_action";
import Loading from "Components/Loading/Loading";
import { Button } from "antd";

const Table = styled.table`
  border-collapse: collapse;
  border: none;
  font-size: 16px;
  width: 100%;
  margin: 0 0 50px 0;
  word-break: keep-all;

  & tr {
    border-bottom: 1px solid #999;
  }

  & tr td:first-child,
  td:nth-child(3) {
    width: 15%;
    text-align: center;
  }

  & tr td:nth-child(2),
  td:nth-child(4) {
    width: 35%;
  }

  ${media.small`
		font-size: 12px;
  `}
`;
const ConfirmArea = styled.div`
  display: flex;

  justify-content: center;
  & button {
    margin: 0 10px;
  }
`;

function InsertProduct() {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [detail, setDetail] = useState();
  const [mainCategory, setMainCategory] = useState("pc");
  const [subCategory, setSubCategory] = useState("desktop");

  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  const [productImages, setProductImages] = useState([]);
  const [productImageFiles, setProductImageFiles] = useState([]);
  const [specImages, setSpecImages] = useState([]);
  const [specImageFiles, setSpecImageFiles] = useState([]);

  const resetImages = () => {
    setImages([]);
    setImageFiles([]);
    setProductImages([]);
    setProductImageFiles([]);
    setSpecImages([]);
    setSpecImageFiles([]);
  };

  const [loading, setLoading] = useState(false);

  const imageFormData = new FormData();

  const Compressor = async (files, option, reader) => {
    const arr = [];

    if (files.length === 0) return arr;

    for (const file of files) {
      const compressedFile = await imageCompression(file, option);

      if (file.size > option.maxSizeMB * 1024 * 1024) {
        reader.readAsDataURL(compressedFile);
      } else {
        reader.readAsDataURL(file);
      }
      reader.onload = () => {
        arr.push(reader.result);
        console.log("pushed");
      };
    }
    return arr;
  };

  const actionImgCompress = async () => {
    console.log("압축 시작");

    //메인이미지 옵션
    const mainOption = {
      // maxSizeMB: 0.3,
      // useWebWorker: true,
    };
    //상품이미지 옵션
    const productOption = {
      // maxSizeMB: 6,
      // useWebWorker: true,
    };
    //스펙이미지 옵션
    const specOption = {
      // maxSizeMB: 6,
      // useWebWorker: true,
    };

    const reader = new FileReader();

    //메인이미지 압축파일
    let mainFileCompressArr = await Compressor(imageFiles, mainOption, reader);

    //상품이미지 압축파일
    let productFileCompressArr = await Compressor(
      productImageFiles,
      productOption,
      reader
    );
    //스펙이미지 압축파일
    let specFileCompressArr = await Compressor(
      specImageFiles,
      specOption,
      reader
    );

    reader.onloadend = () => {
      console.log("압축완료, dataformat 시작");

      //메인이미지 포맷팅
      for (const file of mainFileCompressArr) {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(file.split(",")[1]);

        // Blob를 구성하기 위한 준비
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
          type: "image/jpeg",
        });
        const inputFile = new File([blob], "main.jpg");
        imageFormData.append("images", inputFile);
      }
      //상품이미지 포맷팅
      for (const file of productFileCompressArr) {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(file.split(",")[1]);

        // Blob를 구성하기 위한 준비
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
          type: "image/jpeg",
        });
        const inputFile = new File([blob], "product.jpg");
        imageFormData.append("images", inputFile);
      }
      //스펙이미지 포맷팅
      for (const file of specFileCompressArr) {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(file.split(",")[1]);

        // Blob를 구성하기 위한 준비
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
          type: "image/jpeg",
        });
        const inputFile = new File([blob], "spec.jpg");
        imageFormData.append("images", inputFile);
      }

      dispatch(insertProduct(imageFormData)).then((res) => {
        setLoading(false);
        alert(res.payload.message);
        resetImages();
      });
    };
  };

  const onConfiemButtonHandler = async () => {
    setLoading(true);

    const data = {
      title: title,
      price: price,
      detail: detail,
      category: { main: mainCategory, sub: subCategory },
      images: images,
      contents: { product: productImages, spec: specImages },
    };

    Object.keys(data).forEach((key) => {
      imageFormData.append(key, JSON.stringify(data[key]));
    });

    try {
      await actionImgCompress();
    } catch (e) {
      alert(
        "이미지 처리에 문제가 발생했습니다. 다시 시도하세요.\n(계속 같은 문제가 발생 시 담당자에게 연락하세요)"
      );
      setLoading(false);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <Title>상품 등록</Title>
      <Table>
        <tbody>
          <tr>
            <td>카테고리</td>
            <td>
              <Category
                mainCategory={mainCategory}
                setMainCategory={setMainCategory}
                setSubCategory={setSubCategory}
              />
            </td>
          </tr>
          <tr>
            <td>상품명</td>
            <td>
              <FormTextBar
                setData={setTitle}
                data={title}
                placeholder={"상품명을 입력 해주세요"}
              />
            </td>
            <td>가격</td>
            <td>
              <FormTextBar
                setData={setPrice}
                data={price}
                placeholder={"가격을 입력 해주세요 ex: 100,000"}
                type={"number"}
              />
            </td>
          </tr>
          <tr>
            <td>제품사진</td>
            <td>
              <FileUploader
                images={images}
                setImages={setImages}
                setImageFiles={setImageFiles}
              />
            </td>
            <td colSpan={2}>
              모든 이미지는 사이즈 최적화 및 압축되어 저장됩니다.
            </td>
            <td></td>
          </tr>
          <tr>
            <td>제품특징</td>
            <td>
              <FileUploader
                images={productImages}
                setImages={setProductImages}
                setImageFiles={setProductImageFiles}
              />
            </td>
            <td>스펙</td>
            <td>
              <FileUploader
                images={specImages}
                setImages={setSpecImages}
                setImageFiles={setSpecImageFiles}
              />
            </td>
          </tr>
          <tr>
            <td>상세정보</td>
            <td colSpan={3}>
              <Detail
                detail={detail}
                setDetail={setDetail}
                mainCategory={mainCategory}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <ConfirmArea>
        <Button onClick={onConfiemButtonHandler}>확인</Button>
      </ConfirmArea>
    </>
  );
}

export default InsertProduct;
